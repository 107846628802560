'use strict';

/**
 * Show a spinner inside a given element
 * @param {element} $target - Element to block by the veil and spinner.
 *                            Pass body to block the whole page.
 */
function addSpinner($target) {
    var svgLoaderSrc = $('.spinner-svg');
    var spinnerClass = 'spinner';
    var $veil = $('<div class="veil"></div>');

    if (typeof svgLoaderSrc === 'undefined') {
        svgLoaderSrc = '';
    }
    $veil.append($('<span>').attr('class', spinnerClass).append(svgLoaderSrc.clone()));

    if ($target.get(0).tagName === 'IMG') {
        $target.after($veil);
        $veil.css({ width: $target.width(), height: $target.height() });
        if ($target.parent().css('position') === 'static') {
            $target.parent().css('position', 'relative');
        }
    } else {
        $target.append($veil);
        if ($target.css('position') === 'static') {
            $target.parent().css('position', 'relative');
            $target.parent().addClass('veiled');
        }
        if ($target.get(0).tagName === 'BODY') {
            $veil.find('.spinner').css('position', 'fixed');
        }
    }
    $veil.click(function (e) {
        e.stopPropagation();
    });
}

/**
 * Remove existing spinner
 * @param  {element} $veil - jQuery pointer to the veil element
 */
function removeSpinner($veil) {
    if ($veil.parent().hasClass('veiled')) {
        $veil.parent().css('position', '');
        $veil.parent().removeClass('veiled');
    }
    $veil.off('click');
    $veil.remove();
}

/**
 * Show a spinner inside a given element
 * @param {element} $target - element to show spinner.
 */
function showSpinner($target) {
    var svgLoaderSrc = $('.spinner-svg');
    if (typeof svgLoaderSrc === 'undefined') {
        svgLoaderSrc = '';
    }
    $target.append(svgLoaderSrc.clone()).removeClass('d-none');
}

/**
 * Hide existing spinner
 * @param  {element} $target - element to hide spinner
 */
function hideSpinner($target) {
    $target.empty().addClass('d-none');
}

// element level spinner:
$.fn.spinner = function () {
    var $element = $(this);
    var Fn = function () {
        this.start = function () {
            if ($element.length) {
                addSpinner($element);
            }
        };
        this.stop = function () {
            if ($element.length) {
                var $veil = $('.veil');
                removeSpinner($veil);
            }
        };
        this.show = function () {
            if ($element.length) {
                showSpinner($element);
            }
        };
        this.hide = function () {
            if ($element.length) {
                hideSpinner($element);
            }
        };
    };
    return new Fn();
};

// page-level spinner:
$.spinner = function () {
    var Fn = function () {
        this.start = function () {
            addSpinner($('body'));
        };
        this.stop = function () {
            removeSpinner($('.veil'));
        };
    };
    return new Fn();
};
