'use strict';

var debounce = require('lodash/debounce');
var endpoint = $('.suggestions-wrapper').data('url');
var minChars = 2;
var UP_KEY = 38;
var DOWN_KEY = 40;
var LEFT_KEY = 37;
var RIGHT_KEY = 39;
var ENTER_KEY = 13;
var DIRECTION_DOWN = 1;
var DIRECTION_UP = -1;
var suggestRequest = null;
var searchSubmit = false;

var hasInvertedNavColors = $('header').hasClass('inverted-color')
    && ($('.js-page').data('action') === 'Sites-pacsun-Site' || $('.js-page').data('action') === 'Home-Show');

var appPage = $('.js-page').hasClass('js-page-app-container');
/**
* Sets header iscons white
*/
function setWhiteHeader() {
    if (!appPage) {
        $('.hybrid-nav-desktop-link').addClass('text-white');
        $('.logo-black').addClass('d-none');
        $('.logo-white').removeClass('d-none');
        $('.js-heart-icon').removeClass('is-black');
        $('.search-field').removeClass('is-black');
        $('.header-b-mobile-search-link').removeClass('is-black');
        $('nav').removeClass('bg-white');
        $('.js-mini-cart-icon').removeClass('is-black');
        $('.minicart-quantity').addClass('invert');
        $('.white-quantity').removeClass('d-none');
        $('.black-quantity').addClass('d-none');
        $('.logged-in-white-icon').removeClass('d-none');
        $('.logged-in-black-icon').addClass('d-none');
        $('.header-icon').addClass('stroke-white');
        $('.search-icon').addClass('stroke-white');
        if ($('.dropdown-menu').hasClass('show')) {
            $('.hybrid-nav-desktop-link').removeClass('text-white');
        }
    }
}

/**
 * Sets header icons black
 */
function setBlackHeader() {
    $('nav').addClass('bg-white');
    $('.js-mini-cart-icon').addClass('is-black');
    $('.white-quantity').addClass('d-none');
    $('.black-quantity').removeClass('d-none');
    $('.minicart-quantity').removeClass('invert');
    $('.hybrid-nav-desktop-link').removeClass('text-white');
    $('.logo-black').removeClass('d-none');
    $('.logo-white').addClass('d-none');
    $('.js-heart-icon').addClass('is-black');
    $('.search-field').addClass('is-black');
    $('.header-b-mobile-search-link').addClass('is-black');
    $('.header-icon').removeClass('stroke-white');
    $('.search-icon').removeClass('stroke-white');
    $('.logged-in-white-icon').addClass('d-none');
    $('.logged-in-black-icon').removeClass('d-none');
}

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).siblings('.suggestions-wrapper');
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    if ($(window).innerWidth() < 991) {
        return true;
    }
    return false;
}

/**
 * Tear down Suggestions panel
 */
function tearDownSuggestions() {
    $('input.search-field').val('');
    // $('.suggestions').empty();
    $('input.aa-Input').val('');
    // $('input.aa-Input').removeClass('text-entered');
    // $('input.aa-Input').removeAttr('aria-controls');
}

/**
 * Open the search window
 */
function openSearch() {
    $('body').find('.algolia-suggestions-wrapper').show();
}

/**
 * Open the search window
 */
function openCimulateSearch() {
    $('body').find('.cimulate-wrapper').show();
    $('.cimulate-wrapper').find('.search-content').show();
}

/**
 * Close the search window
 */
function closeSearch() {
    $('.cimulate-wrapper').hide();
    $('.cimulate-wrapper').find('.suggestions').remove();
    $('.algolia-suggestions-wrapper').hide();
    $('.aa-Panel').remove();
}

/**
 * Close the initial search window and open algolia search window
 */
function algoliaSearchBox() {
    $('.algolia-suggestions-wrapper').hide();
    $('.aa-Panel').show();
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
    var mobileSearchIcon = '.search-mobile button.';
    var iconSearch = 'fa-search';
    var iconSearchClose = 'fa-close';

    if (action === 'close') {
        $(mobileSearchIcon + iconSearch).removeClass(iconSearch).addClass(iconSearchClose).attr('type', 'button');
    } else {
        $(mobileSearchIcon + iconSearchClose).removeClass(iconSearchClose).addClass(iconSearch).attr('type', 'submit');
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    $('.suggestions').remove();
    $.spinner().stop();

    if (typeof (response) !== 'object') {
        getSuggestionsWrapper(this).append(response).show();

        if (window.utag_data && !window.utag_data.sent_autocomplete_event && window.utag && window.utag.link) {
            var query = '';
            $('.js-search-input').each(function () {
                if ($(this).val() !== '') {
                    query = $(this).val();
                }
            });
            window.utag.link({
                event_name: 'algolia_analytics',
                event_category: 'algolia',
                event_action: 'autocomplete',
                event_label: query,
                abTestID: 'Cimulate A/B',
                abTestVariantID: 'Cimulate',
                event_noninteraction: 'true'
            });
            window.utag_data.sent_autocomplete_event = true;
        }

        if (isMobileSearch(this)) {
            toggleSuggestionsIcon('close');
        }

        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        var suggestionsList = $('.suggestions .item');
        if ($(suggestionsList).length) {
            $('input.search-field').attr('aria-describedby', 'search-result-count');
        } else {
            $('input.search-field').removeAttr('aria-describedby');
        }

        $('.search-content').hide();
    } else {
        closeSearch();
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars && !searchSubmit) {
        $.spinner().start();
        var url = endpoint + encodeURIComponent($(scope).val()) + '&v=c';
        suggestRequest = $.ajax({
            context: scope,
            url: url,
            method: 'GET',
            success: processResponse,
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 *
 * @param {Integer} direction takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 */
function handleArrow(direction) {
    // get all li elements in the suggestions list
    var suggestionsList = $('.suggestions .item');
    if (suggestionsList.filter('.selected').length === 0) {
        suggestionsList.first().addClass('selected');
        $('input.search-field').each(function () {
            $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
        });
    } else {
        suggestionsList.each(function (index) {
            var idx = index + direction;
            if ($(this).hasClass('selected')) {
                $(this).removeClass('selected');
                $(this).removeAttr('aria-selected');
                if (suggestionsList.eq(idx).length !== 0) {
                    suggestionsList.eq(idx).addClass('selected');
                    suggestionsList.eq(idx).attr('aria-selected', true);
                    $(this).removeProp('aria-selected');
                    $('input.search-field').each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.eq(idx)[0].id);
                    });
                } else {
                    suggestionsList.first().addClass('selected');
                    suggestionsList.first().attr('aria-selected', true);
                    $('input.search-field').each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
                    });
                }
                return false;
            }
            return true;
        });
    }
}

module.exports = {
    baseSearch: function () {
        // Remove popular searches for Cimulate experience
        if ($('#autocomplete').length === 0) $('div.popular-searches div.bottom-title').remove();

        $('form[name="simpleSearch"]').submit(function (e) {
            var suggestionsList = $('.suggestions .item');
            if (suggestionsList.filter('.selected').length !== 0) {
                e.preventDefault();
                suggestionsList.filter('.selected').find('a')[0].click();
            }
        });

        if (navigator.userAgent.indexOf('FABUILD') === -1) {
            $('input.search-field').each(function () {
                /**
                 * Use debounce to avoid making an Ajax call on every single key press by waiting a few
                 * hundred milliseconds before making the request. Without debounce, the user sees the
                 * browser blink with every key press.
                 */
                var debounceSuggestions = debounce(getSuggestions, 300);
                $(this).on('keyup', function (e) {
                    // Capture Down/Up Arrow Key Events
                    if (suggestRequest) {
                        suggestRequest.abort();
                        suggestRequest = null;
                    }
                    switch (e.which) {
                        case DOWN_KEY:
                            handleArrow(DIRECTION_DOWN);
                            e.preventDefault(); // prevent moving the cursor
                            break;
                        case UP_KEY:
                            handleArrow(DIRECTION_UP);
                            e.preventDefault(); // prevent moving the cursor
                            break;
                        case LEFT_KEY: case RIGHT_KEY: case ENTER_KEY:
                            e.preventDefault(); // prevent moving the cursor
                            break;
                        default:
                            debounceSuggestions(this, e);
                    }
                });
            });
        }

        $('.suggestions-wrapper').hide();
    },
    navBMobileSearchHeaderColorChange: function () {
        $('.header-b-mobile-search-button').on('click', function () {
            if ($('header').hasClass('bg-off-white')) {
                $('header').removeClass('bg-off-white');
                $('nav').removeClass('bg-off-white');
                $('.hamburger-menu-trans-black').addClass('d-none');
                $('.hamburger-menu-white').removeClass('d-none');
                $('.acct-icon-black').addClass('d-none');
                $('.acct-icon-white').removeClass('d-none');
                $('.header-b-nav-translucent').removeClass('bg-off-white');

                if (hasInvertedNavColors) {
                    if (($(window).scrollTop() === 0)) {
                        $('.header-icon').addClass('stroke-white');
                        $('.search-icon').addClass('stroke-white');
                        $('.logo-black').addClass('d-none');
                        $('.logo-white').removeClass('d-none');
                        $('.cart-black').addClass('d-none');
                        $('.cart-white').removeClass('d-none');
                        $('.white-quantity').removeClass('d-none');
                        $('.minicart-quantity').addClass('invert');
                        $('.black-quantity').addClass('d-none');
                    }
                }
            } else {
                $('header').addClass('bg-off-white');
                $('nav').addClass('bg-off-white');
                $('.logo-black').removeClass('d-none');

                $('.logo-white').addClass('d-none');

                $('.header-b-search-btn-black').removeClass('d-none');
                $('.header-b-search-btn-white').addClass('d-none');
                $('.hamburger-menu-trans-black').removeClass('d-none');
                $('.hamburger-menu-white').addClass('d-none');
                $('.acct-icon-black').removeClass('d-none');
                $('.acct-icon-white').addClass('d-none');
                $('.cart-black').removeClass('d-none');
                $('.cart-white').addClass('d-none');
                $('.white-quantity').addClass('d-none');
                $('.minicart-quantity').removeClass('invert');
                $('.black-quantity').removeClass('d-none');
                $('.header-b-nav-translucent').addClass('bg-off-white');
            }

            if ($('nav').hasClass('bg-off-white')) {
                $('.header-icon').removeClass('stroke-white');
                $('.search-icon').removeClass('stroke-white');
                $('.cart-black').removeClass('d-none');
                $('.cart-white').addClass('d-none');
                $('.white-quantity').addClass('d-none');
                $('.minicart-quantity').removeClass('invert');
                $('.black-quantity').removeClass('d-none');
            }
        });

        $('.close').on('click', function () {
            $('header').removeClass('bg-off-white');
            $('nav').removeClass('bg-off-white');

            if (!$('.header-b-nav-translucent').length) {
                $('.logo-black').removeClass('d-none');
                $('.logo-white').addClass('d-none');
                $('.hybrid-nav-desktop-link').removeClass('text-white');
            } else {
                $('.logo-black').addClass('d-none');
                $('.logo-white').removeClass('d-none');
                $('.header-b-search-btn-black').addClass('d-none');
                $('.header-b-search-btn-white').removeClass('d-none');
                $('.hamburger-menu-trans-black').addClass('d-none');
                $('.hamburger-menu-white').removeClass('d-none');
                $('.acct-icon-black').addClass('d-none');
                $('.acct-icon-white').removeClass('d-none');
                $('.cart-black').addClass('d-none');
                $('.cart-white').removeClass('d-none');
                $('.white-quantity').removeClass('d-none');
                $('.minicart-quantity').addClass('invert');
                $('.black-quantity').addClass('d-none');
                $('.header-b-nav-translucent').removeClass('bg-off-white');
                if (!appPage) {
                    $('.hybrid-nav-desktop-link').addClass('text-white');
                }
            }


            if (($(window).scrollTop() === 0)) {
                if (hasInvertedNavColors && !appPage) {
                    $('.hybrid-nav-desktop-link').addClass('text-white');
                    $('.logo-black').addClass('d-none');
                    $('.logo-white').removeClass('d-none');
                    $('.header-b-search-btn-black').addClass('d-none');
                    $('.header-b-search-btn-white').removeClass('d-none');
                    $('.hamburger-menu-trans-black').addClass('d-none');
                    $('.hamburger-menu-white').removeClass('d-none');
                    $('.acct-icon-black').addClass('d-none');
                    $('.acct-icon-white').removeClass('d-none');
                    $('.cart-black').addClass('d-none');
                    $('.cart-white').removeClass('d-none');
                    $('.white-quantity').removeClass('d-none');
                    $('.minicart-quantity').addClass('invert');
                    $('.black-quantity').addClass('d-none');
                    $('.heart-white').removeClass('d-none');
                    $('.heart-black').addClass('d-none');
                    $('nav').removeClass('bg-off-white');
                    $('.logged-in-black-icon').addClass('d-none');
                    $('.logged-in-white-icon').removeClass('d-none');
                    $('.hybrid-nav-desktop-link').addClass('text-white');
                }

                if ($('.dropdown-menu').hasClass('show')) {
                    $('.hybrid-nav-desktop-link').removeClass('text-white');
                }

                if ($(window).outerWidth() > 1200 && $('.is-mega-menu').length > 0) {
                    setBlackHeader();
                    $('.logo-black').removeClass('d-none');
                    $('.logo-white').addClass('d-none');
                }

                if (hasInvertedNavColors && ($(window).outerWidth() > 767) && $('.is-mega-menu').length === 0) {
                    setWhiteHeader();
                    $('.logo-black').addClass('d-none');
                    $('.logo-white').removeClass('d-none');
                }
            } else {
                $('.header-icon').removeClass('stroke-white');
                $('.search-icon').removeClass('stroke-white');
            }
        });
    },

    /*
   viewAllNav: function () {
       $('body').on('click', '.view-all', function (e) {
           e.preventDefault();
           var viewAllLink;
           var searchInput = $('body').find('.aa-Autocomplete input.aa-Input.text-entered').val();

           viewAllLink = $('.view-all').eq(0).attr('href');
           var query = viewAllLink.split('?')[1];
           if (!query) {
               viewAllLink += '?q=';
           }

           var combinedViewAll = viewAllLink + searchInput;
           window.location = combinedViewAll;
       });
   },
  */
    searchContent: function () {
        // Regular search & cimulate search
        $('.origSearch input.search-field').on('click', function (e) {
            if ($('body').find('.navbar-nav').children('li').hasClass('nav-b-item-clicked')) {
                $('.nav-b-item').removeClass('nav-b-item-clicked');
                $('.page-header-b-content-asset').removeClass('clicked-top-padding');
                $('.sub-cat-item').removeClass('nav-b-item-clicked');
                $('.nav-b-selection-line').addClass('d-none');
                $('.dropdown-menu').removeClass('show');
                $('.navbar-nav').removeClass('bg-whitesmoke');
                $('.menu-group').removeClass('bg-whitesmoke');
                $('.nav-link').removeClass('font-weight-bold');
                $('.modal-background').hide();
            }
            e.preventDefault();
            var $this = $(this);
            $('.refinement-bar').hide();
            if (!$this.val()) {
                $('.cimulate-wrapper').find('.suggestions').remove();
                openCimulateSearch();
            }

            if (isMobileSearch($this)) {
                $('.suggestions-wrapper').css('top', $('header').height().toString() + 'px');
                $('.search-content-wrapper').addClass('mx-0 w-100');
                $('body').addClass('mobile-nav-height');
                $('.origSearch').find('.closeButtonWrapper').show();
            }
            $('.modal-background').show();
        });

        $('input.search-field').on('keydown', function (e) {
            if (e.key === 'Enter') {
                // Prevent the default action
                e.preventDefault();
                var $this = $(this);
                var query = $this.val();
                if (query.length > 1) {
                    searchSubmit = true;
                    if (suggestRequest) {
                        suggestRequest.abort();
                        suggestRequest = null;
                    }
                    var url = $this.attr('data-url') + '?q=' + encodeURIComponent(query) + '&v=' + $(this).data('version');
                    window.location.href = url;
                }
            }
        });

        /* Algolia Search */
        $('body').on('click', 'input.aa-Input', function (e) {
            if ($('body').find('.navbar-nav').children('li').hasClass('nav-b-item-clicked')) {
                $('.nav-b-item').removeClass('nav-b-item-clicked');
                $('.page-header-b-content-asset').removeClass('clicked-top-padding');
                $('.sub-cat-item').removeClass('nav-b-item-clicked');
                $('.nav-b-selection-line').addClass('d-none');
                $('.dropdown-menu').removeClass('show');
                $('.navbar-nav').removeClass('bg-whitesmoke');
                $('.menu-group').removeClass('bg-whitesmoke');
                $('.nav-link').removeClass('font-weight-bold');
                $('.modal-background').hide();
            }
            e.preventDefault();
            var $this = $(this);
            $('.refinement-bar').hide();
            setTimeout(function () {
                if (!$this.val()) {
                    $('body').find('.aa-Panel').hide();
                    openSearch();
                    var algoliaPopularSearchDiv = $('.algolia-suggestions-wrapper').find('.aa-Source');
                    if (algoliaPopularSearchDiv.length < 1) {
                        var algoliaPopularSearch = $('.aa-PanelSection--left').find('.aa-Source').first();
                        algoliaPopularSearch.appendTo('.popularSearch');
                    }
                }
            }, 300);

            if (isMobileSearch($this)) {
                $('.suggestions-wrapper').css('top', $('header').height().toString() + 'px');
                $('.search-content-wrapper').addClass('mx-0 w-100');
                $('body').addClass('mobile-nav-height');
                $('.mobile-search').find('.aa-ClearIcon').show();
            } else {
                $('.algolia-suggestions-wrapper').css('top', '0px');
            }
            $('.modal-background').show();
        });

        // PIE-898 - fringe case when globale banner loaded and user is actively engaged with search
        $('#GlobaleFreeShippingBannerContainer').one('DOMSubtreeModified', function () {
            setTimeout(function () {
                if (isMobileSearch($(this)) && ($('.algolia-suggestions-wrapper').css('display') !== 'none' || $('.aa-Panel').css('display') !== 'none')) {
                    $('.suggestions-wrapper, .aa-Panel').css('top', $('header').height().toString() + 'px');
                }
            }, 100);
        });

        $('body').on('click', '.modal-background, button.aa-ClearButton, button.cumilate-search-close', function (e) {
            e.preventDefault();
            var $this = $(this);
            closeSearch();
            tearDownSuggestions();
            setTimeout(function () {
                if (!$this.val()) {
                    $('body').find('.aa-Panel').hide();
                    $('body').find('.aa-Input').val('');
                    $('.mobile-search').find('.aa-ClearIcon').hide();
                    $('.origSearch').find('.closeButtonWrapper').hide();
                }
            }, 200);
            if (isMobileSearch($this)) {
                $('.search-content-wrapper').removeClass('mx-0 w-100');
                $('body').removeClass('mobile-nav-height');
            }
            $('.modal-background').hide();
        });

        $('body').on('keypress keyup input', 'input.aa-Input, .origSearch input.search-field', function (e) {
            var $this = $(this);
            var key = e.keyCode || e.charCode;
            if (key === 8 && $this.val() === '') {
                $('body').find('.aa-Panel').hide();
                openSearch();
                $('.cimulate-wrapper').find('.suggestions').remove();
                openCimulateSearch();
                if (isMobileSearch($this)) {
                    $('body').addClass('mobile-nav-height');
                }
                $('.mobile-search').find('.aa-ClearIcon').show();
                $('.origSearch').find('.closeButtonWrapper').show();
                $('.modal-background').show();
            } else if (e.key === 'Escape') {
                e.preventDefault();
                closeSearch();
                tearDownSuggestions();
                setTimeout(function () {
                    if (!$this.val()) {
                        $('body').find('.aa-Panel').hide();
                        $('body').find('.aa-Input').val('');
                        openSearch();
                        $('.cimulate-wrapper').find('.suggestions').remove();
                        $('body').find('input.search-field').val('');
                        openCimulateSearch();
                    }
                }, 200);
            } else if (key === 13 && $this.val() === '') {
                e.preventDefault();
                $('body').find('.aa-Panel').hide();
                openSearch();
                $('.cimulate-wrapper').find('.suggestions').remove();
                openCimulateSearch();
            } else if ($this.val().length > 2) {
                algoliaSearchBox();
                $('.mobile-search').find('.aa-ClearIcon').show();
                $('.modal-background').show();
            }
        });
        $('body').on('click', 'li.nav-item', function () {
            closeSearch();
        });
    },
    brandNav: function () {
        $('body').on('click', '.brand-nav a', function (e) {
            e.preventDefault();
            $('html, body').animate({ scrollTop: $($.attr(this, 'href')).offset().top - $('header').height() }, 700);
        });
    }
};
