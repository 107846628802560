'use strict';

var mobileMenu = require('../components/mobileMenu.js');

var isDesktop = function () {
    if ($(window).innerWidth() > 1200) {
        return true;
    }
    return false;
};

var hasInvertedNavColors = $('header').hasClass('inverted-color')
    && ($('.js-page').data('action') === 'Sites-pacsun-Site'
        || $('.js-page').data('action') === 'Home-Show');

var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
var width = $(window).width();
var appPage = $('.js-page').hasClass('js-page-app-container');

/**
* Sets header iscons white
*/
function setWhiteHeader() {
    if (!appPage) {
        $('.hybrid-nav-desktop-link').addClass('text-white');
        $('.logo-black').addClass('d-none');
        $('.logo-white').removeClass('d-none');
        $('.js-heart-icon').removeClass('is-black');
        $('.search-field').removeClass('is-black');
        $('.header-b-mobile-search-link').removeClass('is-black');
        $('nav').removeClass('bg-white');
        $('.js-mini-cart-icon').removeClass('is-black');
        $('.minicart-quantity').addClass('invert');
        $('.white-quantity').removeClass('d-none');
        $('.black-quantity').addClass('d-none');
        $('.header-icon').addClass('stroke-white');
        $('.search-icon').addClass('stroke-white');
        $('.logged-in-white-icon').removeClass('d-none');
        $('.logged-in-black-icon').addClass('d-none');
        if ($('.dropdown-menu').hasClass('show')) {
            $('.hybrid-nav-desktop-link').removeClass('text-white');
        }
        $('.logoBlack').hide();
        $('.logoWhite').show();
    }
}

/**
 * Sets header icons black
 */
function setBlackHeader() {
    $('nav').addClass('bg-white');
    $('.js-mini-cart-icon').addClass('is-black');
    $('.white-quantity').addClass('d-none');
    $('.black-quantity').removeClass('d-none');
    $('.minicart-quantity').removeClass('invert');
    $('.hybrid-nav-desktop-link').removeClass('text-white');
    $('.js-heart-icon').addClass('is-black');
    $('.search-field').addClass('is-black');
    $('.header-b-mobile-search-link').addClass('is-black');
    $('.header-icon').removeClass('stroke-white');
    $('.search-icon').removeClass('stroke-white');
    $('.logo-black').removeClass('d-none');
    $('.logo-white').addClass('d-none');
    $('.logged-in-white-icon').addClass('d-none');
    $('.logged-in-black-icon').removeClass('d-none');
    $('.logoBlack').show();
    $('.logoWhite').hide();
}

module.exports = {
    initMenu: function () {
        if (!headerBannerStatus || headerBannerStatus < 0) {
            $('.header-banner').removeClass('d-none');
        }

        // Toggles Header Classes on load
        if (hasInvertedNavColors) {
            setWhiteHeader();
        } else {
            setBlackHeader();
        }
    },
    initNanoBar: function () {
        $('.header-banner .close').on('click', function () {
            $('.header-banner').addClass('d-none');
            window.sessionStorage.setItem('hide_header_banner', '1');
        });
    },
    handleMenuInteractions: function () {
        $('body').on('click', '.dropdown:not(.disabled) [data-toggle="dropdown"]', function (e) {
            if (!isDesktop(this)) { // mobile
                e.preventDefault();
                $('.modal-background').show();

                // copy parent element into current UL
                var li = $('<li class="dropdown-item top-category back" role="button"><div class="pull-left mobile-nav-back-button p-3"><i class="fa fa-angle-left fa-2x" aria-hidden="true"></i></div></li>');
                var link = $(this).clone().removeClass('dropdown-toggle')
                    .removeAttr('id')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');
                li.append(link);
                var closeMenu = $('<li class="nav-menu"></li>');
                closeMenu.append($('.close-menu').first().clone());
                $(this).parent().children('.dropdown-menu')
                    .prepend(li)
                    .prepend(closeMenu)
                    .attr('aria-hidden', 'false')
                    .addClass('h-100');

                $('.menu-container').addClass('h-100');
                $('.menu-container').find('.row').addClass('h-100');
                $(this).parent().addClass('show');
                $(this).attr('aria-expanded', 'true');
                $(link).focus();
                $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
            } else { // desktop
                var eventElement = this;
                $('.navbar-nav > li').each(function () {
                    if (!$.contains(this, eventElement)) {
                        $(this).find('.show').each(function () {
                            $('.dropdown-menu').removeClass('show');
                            $('.nav-b-selection-line').addClass('d-none');
                            $('.sub-cat-item').removeClass('font-weight-bold');
                            $(this).find('.nav-link').addClass('font-weight-bold');
                        });
                        if ($(this).hasClass('show')) {
                            $(this).removeClass('show');
                            $(this).children('ul.dropdown-menu').removeClass('show');
                            $(this).children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // need to close all the dropdowns that are not direct parent of current dropdown
                $(this).parent().addClass('show');
                $(this).siblings('.dropdown-menu').addClass('show');
                $(this).attr('aria-expanded', 'true');
            }
        });

        $('.dropdown:not(.disabled) [data-toggle="dropdown"]').parent().on('click', function () {
            if (isDesktop(this)) {
                $(this).removeClass('show');
                $(this).children('.dropdown-menu').removeClass('show');
                $(this).children('.nav-link').attr('aria-expanded', 'false');
            }
        });

        $('.navbar>.close-menu>.close-button').on('click', function (e) {
            e.preventDefault();
            $('body').removeClass('mobile-nav-height');
            $('.menu-container').find('.row').removeClass('h-100');
            $('.menu-container').removeClass('h-100');
            $('.menu-toggleable-left').removeClass('in');
            $('.modal-background').hide();
            $('.navbar-toggler').focus();
            $('.main-menu').attr('aria-hidden', 'true');
            $('.main-menu').siblings().attr('aria-hidden', 'false');
            $('header').siblings().attr('aria-hidden', 'false');
            $('.linc-web-chat').addClass('show-chat');
        });

        $('.navbar-nav').on('click', '.back', function (e) {
            e.preventDefault();
            var $dropDown = $(this).closest('.dropdown');
            var $navMenu = $(this).parent().children('.nav-menu');
            $('.menu-container').find('.row').removeClass('h-100');
            $('.menu-container').removeClass('h-100');
            $(this).closest('.top-category').detach();
            $navMenu.remove();
            $dropDown.removeClass('show');
        });

        $('.navbar-nav').on('click', '.close-button', function (e) {
            e.preventDefault();
            $('.menu-container').find('.row').removeClass('h-100');
            $('.menu-container').removeClass('h-100');
            $('body').removeClass('mobile-nav-height');
            $('.navbar-nav').find('.top-category').detach();
            $('.navbar-nav').find('.nav-menu').detach();
            $('.navbar-nav').find('.show').removeClass('show');
            $('.menu-toggleable-left').removeClass('in');
            $('.main-menu').siblings().attr('aria-hidden', 'false');
            $('header').siblings().attr('aria-hidden', 'false');
            $('.modal-background').hide();
            if ($(window).scrollTop() === 0) {
                $('.navigation').removeClass('bg-white');
                $('.logged-in-black-icon').addClass('d-none');
                $('.logged-in-white-icon').removeClass('d-none');
            }
            $('.linc-web-chat').addClass('show-chat');
        });

        $('.navbar-toggler').on('click', function (e) {
            e.preventDefault();
            if (!mobileMenu.isInit) mobileMenu.init();

            if ($('.suggestions-wrapper').is(':visible')) {
                $('.algolia-suggestions-wrapper').hide();
            }

            $('body').addClass('mobile-nav-height');
            $('.modal-background').show();

            var currMainMenu = $(this).closest('header').find('.main-menu');
            currMainMenu.toggleClass('in');
            currMainMenu.removeClass('d-none');
            currMainMenu.attr('aria-hidden', 'false');
            currMainMenu.siblings().attr('aria-hidden', 'true');

            $('header').siblings().attr('aria-hidden', 'true');
            $('.main-menu').focus();

            $('.linc-web-chat').removeClass('show-chat');
        });

        $('.navbar-header .user').on('mouseenter focusin', function () {
            if ($('.navbar-header .user .popover').is('*')) {
                $('.navbar-header .user .popover').addClass('show');
                $('.user').attr('aria-expanded', 'true');
            }
        });

        $('.navbar-header .user').on('mouseleave', function () {
            $('.navbar-header .user .popover').removeClass('show');
            $('.user').attr('aria-expanded', 'false');
        });

        $('body').on('click', '#myaccount', function (e) {
            e.preventDefault();
        });

        $('.nav-b-item').on('click', function () {
            $('.modal-background').show();
            $('.nav-b-item').addClass('nav-b-item-clicked');
            $('.hybrid-nav-desktop-link').removeClass('text-white');
            $('.sub-cat-item').addClass('nav-b-item-clicked');
            $('.page-header-b-content-asset').addClass('clicked-top-padding');
            $(this).closest('.navbar-nav').addClass('bg-whitesmoke');
            $(this).closest('.menu-group').addClass('bg-whitesmoke');
            $(this).find('.nav-link').addClass('font-weight-bold');
            if ($(this).find('.dropdown-menu').eq(0).hasClass('show')) {
                $(this).find('.dropdown-menu').eq(0).removeClass('show');
            } else {
                $(this).find('.dropdown-menu').eq(0).addClass('show');
            }
            $('.linc-web-chat').addClass('disabled');
        });

        $('.dropright').on('click', function () {
            $('.nav-b-selection-line').addClass('d-none');
            $('.nav-b-dropright').removeClass('show');
            $(this).find('.nav-b-dropright').eq(0).addClass('show');
            $(this).find('.nav-b-selection-line').eq(0).removeClass('d-none');
        });

        // Toggles styles on modal background click
        $('.modal-background, .menuModal').on('click', function () {
            $('.nav-b-item').removeClass('nav-b-item-clicked');
            $('.page-header-b-content-asset').removeClass('clicked-top-padding');
            $('.sub-cat-item').removeClass('nav-b-item-clicked');
            $('.nav-b-selection-line').addClass('d-none');
            $('.dropdown-menu').removeClass('show');
            $('.navbar-nav').removeClass('bg-whitesmoke');
            $('.menu-group').removeClass('bg-whitesmoke');
            $('.nav-link').removeClass('font-weight-bold');
            $('.modal-background').hide();
            $('body').removeClass('mobile-nav-height');
            $('.navbar-nav').find('.top-category').detach();
            $('.navbar-nav').find('.nav-menu').detach();
            $('.navbar-nav').find('.show').removeClass('show');
            $('.menu-toggleable-left').removeClass('in');
            $('.main-menu').siblings().attr('aria-hidden', 'false');
            $('header').siblings().attr('aria-hidden', 'false');

            if ($(window).scrollTop() === 0 && hasInvertedNavColors && !appPage) {
                setWhiteHeader();
            }

            if ($('.nav-b-desktop-toggler').is('*')) {
                $('.nav-b-desktop-toggler').removeClass('d-none');
                $('.toggled-hybrid-menu').addClass('d-none');
            }
            $('.linc-web-chat').removeClass('disabled');
        });

        $('.hybrid-nav-large-font').on('click', function (e) {
            e.preventDefault();
        });

        $('body').on('click', '.logout-link', function () {
            if (window.bridgeWebSDK) window.bridgeWebSDK.setCurrentLoginState(window.bridgeWebSDK.loginStates.isNotLoggedIn);
        });
    },
    handleWindowResize: function () {
        // Toggles styling on window resize
        $(window).on('resize', function () {
            $('.menu-container').find('.row').removeClass('h-100');
            $('.menu-container').removeClass('h-100');
            $('.modal-background').hide();
            $('.nav-b-selection-line').addClass('d-none');
            $('.nav-b-item').removeClass('nav-b-item-clicked');
            $('.page-header-b-content-asset').removeClass('clicked-top-padding');
            $('.sub-cat-item').removeClass('nav-b-item-clicked');
            $('.dropdown-menu').removeClass('show');
            $('.navbar-nav').removeClass('bg-whitesmoke');
            $('.menu-group').removeClass('bg-whitesmoke');
            $('.nav-link').removeClass('font-weight-bold');
            $('body').removeClass('mobile-nav-height');
            $('.navbar-nav').find('.top-category').detach();
            $('.navbar-nav').find('.nav-menu').detach();
            $('.navbar-nav').find('.show').removeClass('show');
            $('.menu-toggleable-left').removeClass('in');
            $('.main-menu').siblings().attr('aria-hidden', 'false');

            if ($('.header-b-nav-translucent').is('*') && !appPage) {
                $('.hybrid-nav-desktop-link').addClass('text-white');
            }

            if ($('.nav-b-desktop-toggler').is('*')) {
                $('.nav-b-desktop-toggler').removeClass('d-none');
                $('.toggled-hybrid-menu').addClass('d-none');
            }

            if ($(window).scrollTop() === 0 && $('.home-page').is('*') && hasInvertedNavColors && !appPage) {
                if ($(window).outerWidth() < 767 && $(this).width() !== width) {
                    $('.search-close').trigger('click');
                }
                setWhiteHeader();
            }
            width = $(this).width();
        });
    },
    handleTransparentNav: function () {
        if (hasInvertedNavColors && !appPage) {
            // Gives Translucent Nav Functionality
            $(window).on('scroll', function () {
                if ($(window).scrollTop() > 25) {
                    setBlackHeader();
                } else if ($('.nav-b-item-clicked').is('*') || $('.navigation:hover').is('*')) {
                    setBlackHeader();
                } else if ($(window).scrollTop() < 25 && !$('.navigation:hover').is('*') && !appPage) {
                    setWhiteHeader();
                } else {
                    setWhiteHeader();
                    if ($('.dropdown-menu').hasClass('show') && !appPage) {
                        $('.hybrid-nav-desktop-link').removeClass('text-white');
                    }
                }
            });
            // Toggles styles on hover
            $('nav').on('mouseenter', function () {
                if (!$('.header-b-search-modal').hasClass('show') && $(window).scrollTop() < 25) {
                    $('nav').removeClass('header-b-nav-translucent pb-3');
                    $('nav').addClass('nav-mb');
                    setBlackHeader();
                }

                $('.header-b-mobile-search-button').on('click', function () {
                    $('.logo-black').removeClass('d-none');
                    $('.logo-white').addClass('d-none');
                    $('.js-mini-cart-icon').addClass('is-black');
                    $('.search-field').addClass('is-black');
                    $('.header-b-mobile-search-link').addClass('is-black');
                    $('.minicart-quantity').removeClass('invert');
                    $('.white-quantity').addClass('d-none');
                    $('.black-quantity').removeClass('d-none');
                    $('.js-heart-icon').addClass('is-black');
                });
            }).on('mouseleave', function () {
                if ($('.suggestions-wrapper').is(':visible') && $(window).scrollTop() === 0) {
                    return;
                }
                if (!$('.header-b-search-modal').hasClass('show') && !$('.nav-b-item-clicked').is('*')) {
                    if ($(window).scrollTop() === 0) {
                        $('nav').addClass('nav-mb');
                        setWhiteHeader();
                    }
                }
                if ($('.nav-b-item-clicked').is('*')) {
                    $('.navigation').addClass('bg-white');
                    $('.logo-black').removeClass('d-none');
                    $('.logo-white').addClass('d-none');
                    $('.header-icon').removeClass('stroke-white');
                    $('.search-icon').removeClass('stroke-white');
                    $('.js-mini-cart-icon').addClass('is-black');
                    $('.minicart-quantity').removeClass('invert');
                    $('.white-quantity').addClass('d-none');
                    $('.black-quantity').removeClass('d-none');
                    $('.js-heart-icon').addClass('is-black');
                    setBlackHeader();
                    $('.logged-in-black-icon').removeClass('d-none');
                    $('.logged-in-white-icon').addClass('d-none');
                }

                if (hasInvertedNavColors && !appPage && $('.home-page').is('*') && $(window).scrollTop() === 0 && !$('.nav-b-item-clicked').is('*')) {
                    $('.navigation').removeClass('bg-white');
                }
            });
        }
    },
    applyNavPromo: function () {
        // Allows user to automatically apply promos from hybrid nav promo flyout
        $('.promo-applier').on('click', function (e) {
            e.preventDefault();
            var promoValue = $(this).data('promo');
            $(this).find('.checked').removeClass('d-none');
            $(this).find('.unchecked').addClass('d-none');
            navigator.clipboard.writeText(promoValue);
        });
    },
    focusOnSearchINput: function () {
        $('#search-modal').on('shown.bs.modal', function () {
            $('.js-search-input').trigger('focus');
        });
    }
};
