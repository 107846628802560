window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./navigation/hybridMenu'));

    if ($('header').hasClass('header-app')) {
        processInclude(require('./navigation/menuApp'));
    }
    processInclude(require('base/components/cookie'));
    if (!window.OneTrustEnabled) {
        processInclude(require('./components/consentTracking'));
    }
    processInclude(require('./components/padStart'));
    processInclude(require('./components/stickyfill'));
    processInclude(require('./components/carousels'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('./components/floatLabels'));
    processInclude(require('./components/tooltips'));
    processInclude(require('./components/stackSwiper'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('./product/quickShop'));
    processInclude(require('likes/likes/likes'));
    processInclude(require('./product/afterpayContent'));
    processInclude(require('./datalayer/tealium'));
    processInclude(require('./thirdParty/perimeterx'));
    processInclude(require('catch/catchInit'));
    processInclude(require('./thirdParty/bryj'));
});
require('./plugins/jquery.plugin');
require('./plugins/jquery.countdown');
require('./thirdParty/bootstrap');
require('./components/spinner');
require('slick-carousel');
require('picturefill');
require('jquery.waitforimages');
require('jquery.cookie');
require('loading-attribute-polyfill');
