'use strict';
var loadingAttributePolyfill = require('loading-attribute-polyfill').default;

/**
 *
 * @returns {boolean} is user logged in
 */
function isLoggedIn() {
    return $('.logged-in-menu').is('*');
}

/**
 *
 * @param {Object} guestLikes saved guest likes
 */
function getGuestLikes(guestLikes) {
    var $noLikes = $('.js-no-likes');
    if ($noLikes.is('*')) {
        $.ajax({
            url: $noLikes.data('action'),
            data: $.param(guestLikes)
        }).done(function (response) {
            var el = $('<div></div>').html(response);
            var product = el.find('.product');
            var likeTileImg = product.find('.tile-image[data-unloaded]');
            likeTileImg.each(function () {
                var $tileImg = $(this).find('noscript.loading-lazy');
                if ($tileImg.length) {
                    loadingAttributePolyfill.prepareElement($tileImg[0]);
                }
                $(this).removeAttr('data-unloaded');
            });
            $noLikes.addClass('d-none');
            $('.js-likes-slot').addClass('d-none');
            $('.js-likes-products').html(product);
        });
    }
}

/**
 *
 * @param {string} productId id of liked product master
 * @param {string} colorId id of iked color
 */
function setGuestLikes(productId, colorId) {
    var guestLikesStore = localStorage.getItem('ProductLikes');
    var likeId = productId + '_' + colorId;
    var savedLikes;

    // If there are saved likes...
    if (guestLikesStore) {
        savedLikes = JSON.parse(guestLikesStore);
        // If this is a new like, add it to existing like list...
        if (savedLikes.likes.indexOf(likeId) === -1) {
            savedLikes.likes.push(likeId);
            // ...otherwise, remove it from the list
        } else {
            savedLikes.likes.forEach(function (savedLike, i) {
                if (savedLike === likeId) {
                    savedLikes.likes.splice(i, 1);
                }
            });
        }
        // ...otherwise, create saved likes
    } else {
        savedLikes = {
            likes: [likeId]
        };
    }
    // Save new likes to local storage
    localStorage.setItem('ProductLikes', JSON.stringify(savedLikes));
    // Toggle header like button
    if (savedLikes.likes.length) {
        $('.js-likes-page-link').addClass('hasLikes');
    } else {
        $('.js-likes-page-link').removeClass('hasLikes');
    }
}

/**
 *
 * @param {Obejct} $likeRemove jQuery like toggle button object
 */
function removeGuestLike($likeRemove) {
    var pid = $likeRemove.data('productId');
    var colorId = $likeRemove.data('colorId');
    var guestLikesStore = localStorage.getItem('ProductLikes');
    var likeId = pid + '_' + colorId;
    var savedLikes;

    // If there are saved likes...
    if (guestLikesStore) {
        savedLikes = JSON.parse(guestLikesStore);
        if (savedLikes.likes.indexOf(likeId) >= 0) {
            savedLikes.likes.forEach(function (savedLike, i) {
                if (savedLike === likeId) {
                    savedLikes.likes.splice(i, 1);
                }
            });
        }
        // Save new likes to local storage
        localStorage.setItem('ProductLikes', JSON.stringify(savedLikes));
        // Toggle header like button
        if (savedLikes.likes.length) {
            $('.js-likes-page-link').addClass('hasLikes');
        } else {
            $('.js-likes-page-link').removeClass('hasLikes');
        }
    }
}

/**
 *
 * @param {Obejct} $likeRemove jQuery like remove button object
 */
function removeLike($likeRemove) {
    $.ajax({
        url: $likeRemove.data('action'),
        data: {
            pid: $likeRemove.data('productId'),
            colorId: $likeRemove.data('colorId'),
            addLike: false
        }
    })
        .done(function () {
            $likeRemove.closest('.product').remove();

            if (!isLoggedIn()) {
                removeGuestLike($likeRemove);
            }
            if (!$('.js-likes-products').children('.product').is('*')) {
                $('.js-no-likes').removeClass('d-none');
                $('html, body').animate({ scrollTop: 0 });
            }
        });
}

/**
 *
 * @param {Object} $likeToggle jQuery like toggle button object
 */
function toggleLike($likeToggle) {
    var addLike = !$likeToggle.data('isLiked');
    var colorId = $likeToggle.data('colorId');

    $likeToggle.data('isLiked', addLike);

    $.ajax({
        url: $likeToggle.data('action'),
        data: {
            pid: $likeToggle.data('productId'),
            colorId: colorId,
            addLike: addLike
        }
    })
        .done(function (response) {
            var $likeCount = $likeToggle.children('.likes-count');

            $likeCount.text(response.count);

            if (!isLoggedIn()) {
                setGuestLikes(response.productId, colorId);
            } else if (response.customerHasLikes) {
                $('.js-likes-page-link').addClass('hasLikes');
            } else {
                $('.js-likes-page-link').removeClass('hasLikes');
            }

            if (response.action === 'added') {
                $likeToggle.addClass('selected');
                $likeCount.removeClass('d-none');
            } else {
                $likeToggle.removeClass('selected');
                $likeCount.addClass('d-none');
            }
        })
        .fail(function () { });
}

/**
 *
 * @param {Object} guestLikes saved guest likes
 */
function setGuestLikesButtons(guestLikes) {
    guestLikes.likes.forEach(function (like) {
        var splitLikeId = like.split('_');
        var productId = splitLikeId[0];
        var colorId = splitLikeId[1];
        var $likeButton = $('.js-like-toggle[data-product-id="' + productId + '"][data-color-id="' + colorId + '"], .js-like-toggle[data-product-master-id="' + productId + '"][data-color-id="' + colorId + '"]');

        $likeButton
            .addClass('selected')
            .data('isLiked', true)
            .children('.likes-count')
            .removeClass('d-none');
    });
}

module.exports = {
    guestLikes: function () {
        if (!isLoggedIn()) {
            var guestLikesStore = localStorage.getItem('ProductLikes');

            if (guestLikesStore) {
                var guestLikes = JSON.parse(guestLikesStore);

                if (guestLikes.likes.length) {
                    $('.js-likes-page-link').addClass('hasLikes');
                    setGuestLikesButtons(guestLikes);
                    getGuestLikes(guestLikes);
                }
            }
        }
    },
    showLike: function () {
        // eslint-disable-next-line consistent-return
        $('body').on('pointerenter pointerleave', '.tile-image-container', function (e) {
            if (e.pointerType !== 'mouse') return false;
            var opacity = e.type === 'pointerenter' ? 1 : 0;
            $(this).find('.js-like-toggle').css('opacity', opacity);
        });
    },
    toggleLike: function () {
        // Display refinements bar when Menu icon clicked
        $('body').on('click', '.js-like-toggle', function () {
            toggleLike($(this));
        });
    },
    removeLike: function () {
        $('body').on('click', '.js-like-remove', function (e) {
            e.preventDefault();
            removeLike($(this));
        });
    },
    updateLikeToggle: function () {
        $('body').on('product:variantChanged', function (e, response) {
            var $newLikeHeartButton = $(response.heartIconHtml).find('.js-like-toggle');
            $('.js-like-toggle').replaceWith($newLikeHeartButton);
        });
    }
};
