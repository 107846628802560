'use strict';

var Stack = require('../thirdParty/stack');
var SwipeListener = require('swipe-listener/dist/swipe-listener');

/**
 * @description Init the default stack
 */
function initDefaultStack() {
    var $stackDefault = $('.stack--default');
    $stackDefault.each(function () {
        if ($(this).find('.stack__item').length < 2) return;

        // Init the Stack
        var stackInst = new Stack(this, {
            perspective: 4000,
            perspectiveOrigin: '300% 300%',
            visible: 3,
            infinite: true
        });

        // Listen for the 'swipe' event
        SwipeListener(this.parentNode); // eslint-disable-line new-cap
        this.parentNode.addEventListener('swipe', function (e) {
            if (e.detail.directions.left) {
                stackInst.reject();
            }
            if (e.detail.directions.right) {
                stackInst.accept();
            }
        });
    });
}

/**
 * @description Init the stack swiper
 */
function init() {
    initDefaultStack();
}

module.exports = {
    init: init
};
