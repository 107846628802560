'use strict';

var Stickyfill = require('stickyfilljs');

/**
 * Add position: sticky polyfill
 */

var initStickyPolyfill = function () {
    var sticky = $('.is-sticky');
    var stickySoft = $('.is-sticky-soft');

    if (sticky.length) {
        Stickyfill.add(sticky);
    }

    if (stickySoft.length) {
        Stickyfill.add(stickySoft);
    }
};

initStickyPolyfill();
