'use strict';

var classie = require('classie/lib/classie');
var dynamics = require('dynamics.js/lib/dynamics');

var onEndAnimation = function (el, callback) {
    var onEndCallbackFn = function (ev) {
        if (ev.target !== this) return;
        this.removeEventListener('animationend', onEndCallbackFn);
        if (callback && typeof callback === 'function') {
            callback.call();
        }
    };
    el.addEventListener('animationend', onEndCallbackFn);
};

/**
 * Extend the object properties
 * @param {Object} a - base object
 * @param {Object} b - target object
 * @return {Object} - extended object
 */
function extend(a, b) {
    for (var key in b) { // eslint-disable-line no-restricted-syntax
        if (b.hasOwnProperty(key)) { // eslint-disable-line no-prototype-builtins
            a[key] = b[key]; // eslint-disable-line no-param-reassign
        }
    }
    return a;
}

/**
 * The constructor for the Stack
 * @param {Element} el - target element
 * @param {Object} options - config
 * @constructor
 */
function Stack(el, options) {
    classie.add(el.parentNode, 'stack-container--init');
    this.el = el;
    this.options = extend({}, this.options);
    extend(this.options, options);
    this.items = [].slice.call(this.el.children);
    this.itemsTotal = this.items.length;
    if (this.itemsTotal < this.options.visible) {
        this.options.visible = this.itemsTotal;
    }
    this.current = 0;
    el.parentNode.setAttribute('data-visible-items-count', this.options.visible);
    this._init(); // eslint-disable-line
}

Stack.prototype.options = {
    // stack's perspective value
    perspective: 1000,
    // stack's perspective origin
    perspectiveOrigin: '50% -50%',
    // number of visible items in the stack
    visible: 3,
    // infinite navigation
    infinite: true,
    // callback: when reaching the end of the stack
    onEndStack: function () {
        return false;
    },
    // animation settings for the items' movements in the stack when the items rearrange
    // object that is passed to the dynamicsjs animate function (see more at http://dynamicsjs.com/)
    // example:
    // {type: dynamics.spring,duration: 1641,frequency: 557,friction: 459,anticipationSize: 206,anticipationStrength: 392}
    stackItemsAnimation: {
        duration: 500,
        type: dynamics.bezier,
        points: [{ x: 0, y: 0, cp: [{ x: 0.25, y: 0.1 }] }, { x: 1, y: 1, cp: [{ x: 0.25, y: 1 }] }]
    },
    // delay for the items' rearrangement / delay before stackItemsAnimation is applied
    stackItemsAnimationDelay: 0
    // animation settings for the items' movements in the stack before the rearrangement
    // we can set up different settings depending on whether we are approving or rejecting an item
    /*
    stackItemsPreAnimation: {
        reject: {
            // if true, then the settings.properties parameter will be distributed through the items in a non equal fashion
            // for instance, if we set settings.properties = {translateX:100} and we have options.visible = 4,
            // then the second item in the stack will translate 100px, the second one 75px and the third 50px
            elastic: true,
            // object that is passed into the dynamicsjs animate function - second parameter -  (see more at http://dynamicsjs.com/)
            animationProperties: {},
            // object that is passed into the dynamicsjs animate function - third parameter - (see more at http://dynamicsjs.com/)
            animationSettings: {}
        },
        accept: {
            // if true, then the settings.properties parameter will be distributed through the items in a non equal fashion
            // for instance, if we set settings.properties = {translateX:100} and we have options.visible = 4,
            // then the second item on the stack will translate 100px, the second one 75px and the third 50px
            elastic: true,
            // object that is passed into the dynamicsjs animate function - second parameter -  (see more at http://dynamicsjs.com/)
            animationProperties: {},
            // object that is passed into the dynamicsjs animate function (see more at http://dynamicsjs.com/)
            animationSettings: {}
        }
    }
    */
};

// set the initial styles for the visible items
Stack.prototype._init = function () { // eslint-disable-line no-underscore-dangle
    // set default styles
    // first, the stack
    this.el.style.WebkitPerspective = this.el.style.perspective = this.options.perspective + 'px';
    this.el.style.WebkitPerspectiveOrigin = this.el.style.perspectiveOrigin = this.options.perspectiveOrigin;

    // the items
    for (var i = 0; i < this.itemsTotal; ++i) {
        var item = this.items[i];
        if (i < this.options.visible) {
            if (i === 0) {
                item.style.opacity = 1;
            } else {
                item.style.opacity = 1 - (i / this.options.visible);
            }
            item.style.pointerEvents = 'auto';
            item.style.zIndex = i === 0 ? parseInt(this.options.visible + 1, 10) : parseInt(this.options.visible - i, 10);
            item.style.WebkitTransform = item.style.transform = 'translate3d(0px, 0px, ' + parseInt(-1 * 50 * i, 10) + 'px)';
        } else {
            item.style.WebkitTransform = item.style.transform = 'translate3d(0, 0, -' + parseInt(this.options.visible * 50, 10) + 'px)';
        }
    }

    classie.add(this.items[this.current], 'stack__item--current');
};

Stack.prototype.reject = function (callback) {
    this._next('reject', callback); // eslint-disable-line no-underscore-dangle
};

Stack.prototype.accept = function (callback) {
    this._next('accept', callback); // eslint-disable-line no-underscore-dangle
};

Stack.prototype.restart = function () {
    this.hasEnded = false;
    this._init(); // eslint-disable-line no-underscore-dangle
};

Stack.prototype._next = function (action, callback) { // eslint-disable-line no-underscore-dangle
    if (this.isAnimating || (!this.options.infinite && this.hasEnded)) return;
    this.isAnimating = true;

    // current item
    var currentItem = this.items[this.current];
    classie.remove(currentItem, 'stack__item--current');

    // add animation class
    classie.add(currentItem, action === 'accept' ? 'stack__item--accept' : 'stack__item--reject');

    var self = this;
    onEndAnimation(currentItem, function () {
        // reset current item

        if (self.itemsTotal > self.options.visible) {
            currentItem.style.opacity = 0;
        } else {
            currentItem.style.opacity = 1 - ((self.itemsTotal - 1) / self.options.visible);
        }

        currentItem.style.pointerEvents = 'none';
        currentItem.style.WebkitTransform = currentItem.style.transform = 'translate3d(0px, 0px, -' + parseInt(self.options.visible * 50, 10) + 'px)';

        classie.remove(currentItem, action === 'accept' ? 'stack__item--accept' : 'stack__item--reject');

        self.items[self.current].style.zIndex = self.options.visible + 1;
        self.isAnimating = false;

        if (callback) callback();

        if (!self.options.infinite && self.current === 0) {
            self.hasEnded = true;
            // callback
            self.options.onEndStack(self);
        }
    });

    // set style for the other items
    for (var i = 0; i < this.itemsTotal; ++i) {
        if (i >= this.options.visible) break;

        var pos;
        if (!this.options.infinite) {
            if (this.current + i >= this.itemsTotal - 1) break;
            pos = this.current + i + 1;
        } else {
            pos = this.current + i < this.itemsTotal - 1 ? this.current + i + 1 : i - (this.itemsTotal - this.current - 1);
        }

        var item = this.items[pos];
        // stack items animation
        var animateStackItems = function (elem, idx) {
            if (idx === 0) {
                elem.style.opacity = 1; // eslint-disable-line no-param-reassign
            } else {
                elem.style.opacity = 1 - (idx / self.options.visible); // eslint-disable-line no-param-reassign
            }

            elem.style.zIndex = parseInt(self.options.visible - idx, 10); // eslint-disable-line no-param-reassign
            elem.style.pointerEvents = 'auto'; // eslint-disable-line no-param-reassign

            dynamics.animate(elem, {
                translateZ: parseInt(-1 * 50 * idx, 10)
            }, self.options.stackItemsAnimation);
        };

        setTimeout(function (item, i) { // eslint-disable-line
            return function () {
                var preAnimation;

                if (self.options.stackItemsPreAnimation) {
                    preAnimation = action === 'accept' ? self.options.stackItemsPreAnimation.accept : self.options.stackItemsPreAnimation.reject;
                }

                if (preAnimation) {
                    // items "pre animation" properties
                    var animProps = {};

                    for (var key in preAnimation.animationProperties) { // eslint-disable-line
                        var interval = preAnimation.elastic ? preAnimation.animationProperties[key] / self.options.visible : 0;
                        animProps[key] = preAnimation.animationProperties[key] - Number(i * interval);
                    }

                    // this one remains the same..
                    animProps.translateZ = parseInt(-1 * 50 * (i + 1), 10);

                    preAnimation.animationSettings.complete = function () {
                        animateStackItems(item, i);
                    };

                    dynamics.animate(item, animProps, preAnimation.animationSettings);
                } else {
                    animateStackItems(item, i);
                }
            };
        }(item, i), this.options.stackItemsAnimationDelay);
    }

    // update current
    this.current = this.current < this.itemsTotal - 1 ? this.current + 1 : 0;
    classie.add(this.items[this.current], 'stack__item--current');
};

module.exports = Stack;
