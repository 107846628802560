'use strict';

var util = require('../util/util');

/**
 * Reusable slick carousel configurations
 * @example - $('.product-carousel').slick(slickConfigs.pdp)
 */

module.exports = {
    productTileSlider: {
        adaptiveHeight: true,
        centerMode: true,
        slidesToShow: 4,
        swipeToSlide: true,
        touchThreshould: 100,
        responsive: [
            {
                breakpoint: util.getViewports('md'),
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    speed: 100
                }
            }
        ]
    },
    productTileAppSlider: {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 600,
        adaptiveHeight: true,
        draggable: true,
        cssEase: 'ease-out',
        variableWidth: false,
        centerMode: false,
        slide: '.js-product-tile-app-carousel-slide',
        slidesToShow: 4,
        rows: 0,
        responsive: [
            {
                breakpoint: util.getViewports('md'),
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    infinite: false,
                    initialSlide: 1
                }
            }
        ]
    },
    miniCartProductTileSlider: {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 600,
        draggable: true,
        cssEase: 'ease-out',
        slidesToShow: 2.5,
        centerPadding: 0,
        centerMode: false,
        rows: 1
    },
    pdpImageCarousel: {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 600,
        adaptiveHeight: true,
        cssEase: 'ease-out',
        variableWidth: false,
        centerMode: false,
        slide: '.carouselItem',
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 0
    },
    pdpVideoImageCarousel: {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 600,
        adaptiveHeight: true,
        cssEase: 'ease-out',
        variableWidth: false,
        centerMode: false,
        slide: '.carouselItem',
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 0,
        // initialSlide: 1,
        waitForAnimate: false
    },
    pdpShopTheLookCarousel: {
        adaptiveHeight: true,
        variableWidth: true,
        slidesToShow: 2,
        swipeToSlide: true,
        touchThreshould: 100,
        responsive: [
            {
                breakpoint: util.getViewports('md'),
                settings: {
                    arrows: false,
                    speed: 100
                }
            }
        ]
    },
    footerStickyPromoSlider: {
        speed: 600,
        autoplay: true
    },
    memberExclusivesCarousel: {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 600,
        cssEase: 'ease-out',
        variableWidth: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 0,
        slide: '.carouselItem',
        responsive: [
            {
                breakpoint: util.getViewports('md'),
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    },
    likesCarousel: {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 600,
        cssEase: 'ease-out',
        variableWidth: false,
        centerMode: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        rows: 0,
        slide: '.carouselItem',
        responsive: [
            {
                breakpoint: util.getViewports('md'),
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '32px'
                }
            }
        ]
    },
    likesRecommendations: {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 600,
        adaptiveHeight: true,
        draggable: true,
        cssEase: 'ease-out',
        variableWidth: false,
        centerMode: true,
        slide: '.js-product-tile-carousel-slide',
        slidesToShow: 4,
        rows: 0,
        responsive: [
            {
                breakpoint: util.getViewports('md'),
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    centerPadding: '10px'
                }
            }
        ]
    },
    shopNowCarousel: {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 600,
        cssEase: 'ease-out',
        variableWidth: false,
        centerMode: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        rows: 0,
        slide: '.carouselItem',
        responsive: [
            {
                breakpoint: util.getViewports('md'),
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '32px'
                }
            }
        ]
    }
};
