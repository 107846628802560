'use strict';

module.exports = {
    initTooltips: function () {
        // Set up Bootstrap Popovers
        $('[data-toggle="tooltip"]').each(function () {
            // tooltips by default should display on Hover/focus actions
            $(this).popover({
                trigger: 'hover focus'
            });
        });

        $('body').on('mouseenter focusin', '.info-icon', function () {
            $(this).find('.tooltip').removeClass('d-none');
        });

        $('body').on('mouseleave focusout', '.info-icon', function () {
            $(this).find('.tooltip').addClass('d-none');
        });
    }

};
