'use strict';

/**
 * get shipping option selected
 * @param {Object} self - element
 * @returns {string} option selected
 */
function getShippingOptions(self) {
    var $self = self;
    var option;
    if ($self) {
        var parentDiv = $self.parent().parent();
        var name = $self.parent().find('.shipping-method-option').text().trim();
        var price = parentDiv.find('.bfx-price').text();
        var time = parentDiv.find('.arrival-time').text();
        option = name + ': ' + price + ' ' + time;
    }

    return option;
}

/**
 * Get payment option selected
 * @param {Object} $self element
 * @returns {string} option selected
 */
function getPaymentOptions($self) {
    var paymentMethodMap = {
        CREDIT_CARD: 'Credit Card',
        AFTERPAY_PBI: 'Afterpay',
        PayPal: 'PayPal',
        DW_APPLE_PAY: 'Apple Pay',
        GIFT_CARD: 'Gift Card'
    };

    return paymentMethodMap[$self.parent().data('method-id')];
}

/**
 * Get billing option selected
 * @param {Object} self element
 * @returns {string} option selected
 */
function getBillingOptions(self) {
    var $self = self;
    return $self.parent().find('label').text();
}

/**
 * Normalize the data from newletter form to trigger the event
 * @param {Object} data data from newsletter form
 * @returns {Object} normalized data expected on datalayer
 */
function getNewsletterNormalizedData(data) {
    var category = [];
    var eventType = [];
    var errors = [];

    if (data && data.shoppingcatwomen) {
        category.push('women');
    }

    if (data && data.shoppingcatmen) {
        category.push('men');
    }

    if (data && data.shoppingcatunisex) {
        category.push('unisex');
    }

    if (data && data.shoppingcatkids) {
        category.push('kids');
    }

    eventType.push('email');

    if (data && data.mobileNumber) {
        eventType.push('sms');
    }

    if (data.errors && data.errors.length > 0) {
        errors = data.errors;
    }

    return {
        category: category,
        eventType: eventType,
        errors: errors
    };
}

/**
 * Get option selected from step
 * @param {Object} stage step number
 * @returns {string} option selected
 */
function getOptions(stage) {
    var option;
    if (stage === 3) {
        option = getShippingOptions($(':checked', $('.shipping-method-list')));
    }

    if (stage === 4) {
        option = getPaymentOptions($('ul.payment-options > li > a.active'));
    }

    if (stage === 5) {
        option = getBillingOptions($('.js-billing-address-checkbox').parent().find(':checked'));
    }

    return option;
}

/**
 *
 * @param {Object} event javascript event to load after google analytics is ready
 */
function triggerEventAfterGALoad(event) {
    if (window.ga && window.ga.loaded) {
        $(document).trigger(event);
    } else {
        setTimeout(triggerEventAfterGALoad(event), 1000);
    }
}

/**
 * Get cookie value by name
 * @param {string} cookieName - cookie name
 * @returns {string} cookie value
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

var exports = {
    addToCartEvent: function () {
        $('body').on('event:AddToCart', function (_, data) {
            var event = $.Event('AddToCart'); // eslint-disable-line
            event.selectedOptions = {
                product_id: data.product_id,
                pname: data.pname,
                brand: data.brand,
                price: data.price,
                Quantity: data.Quantity,
                category: data.category,
                product_color: data.product_color,
                bopis: data.bopis,
                discount: data.discount ? data.discount.discount : undefined,
                product_sku: data.product_sku,
                markdown_type: data.discount ? data.discount.markdownType : undefined,
                product_dept: data.product_dept,
                product_division: data.product_division,
                product_subdept: data.product_subdept,
                order_type: data.order_type
            };

            $(document).trigger(event);
        });
    },
    removeFromCartEvent: function () {
        $('body').on('event:RemoveFromCart', function (_, data) {
            var event = $.Event('RemoveFromCart'); // eslint-disable-line
            event.product_id = data.product_id;
            event.product_name = data.pname;
            event.product_brand = data.brand;
            event.price = data.price;
            event.quantity = data.Quantity;
            event.product_category = data.category;
            event.product_color = data.product_color;
            event.bopis = data.bopis;
            event.discount = data.discount;
            event.product_sku = data.product_sku;
            event.markdown_type = data.markdown_type;
            event.order_type = data.order_type;
            $(document).trigger(event);
        });
    },
    checkoutStep: function () {
        var checkoutStages = [
            'Contact Info',
            'Shipping Info',
            'Shipping Method',
            'Payment Method',
            'Billing',
            'Place Order'
        ];

        $('body').on('event:CheckoutStep', function (_, data) {
            var checkoutStepEvent = $.Event('CheckoutStep'); // eslint-disable-line
            var $checkoutMain = $('#checkout-main');
            var hasLoyaltyPoints = $checkoutMain.data('order-points') && $checkoutMain.data('order-points') > 0 ? 'yes' : 'no';

            if (data.stage === 3) data.stage = 5; // eslint-disable-line no-param-reassign
            if (data.stage === 2) data.stage = 3; // eslint-disable-line no-param-reassign
            if (data.stage === 1) data.stage = 2; // eslint-disable-line no-param-reassign

            checkoutStepEvent.step_number = data.stage + 1;
            checkoutStepEvent.step_name = checkoutStages[data.stage];
            checkoutStepEvent.checkout_option = getOptions(data.stage + 1);
            checkoutStepEvent.pacsun_updates = $checkoutMain.data('order-pacsunupdates');
            checkoutStepEvent.sms_updates = $checkoutMain.data('order-smsupdates');
            checkoutStepEvent.applied_promo = $checkoutMain.data('order-appliedpromo');
            checkoutStepEvent.applied_loyalty = hasLoyaltyPoints;
            checkoutStepEvent.order_giftcart_amount = $checkoutMain.data('order-totalgiftpayment');
            checkoutStepEvent.order_type = $checkoutMain.data('order-type');

            if (data.stage === 0) {
                var checkoutStepEvent2 = $.Event('CheckoutStep'); // eslint-disable-line
                checkoutStepEvent2.step_number = data.stage + 2;
                checkoutStepEvent2.step_name = checkoutStages[data.stage + 1];
                checkoutStepEvent2.checkout_option = getOptions(data.stage + 2);
                checkoutStepEvent2.pacsun_updates = $checkoutMain.data('order-pacsunupdates');
                checkoutStepEvent2.sms_updates = $checkoutMain.data('order-smsupdates');
                checkoutStepEvent2.applied_promo = $checkoutMain.data('order-appliedpromo');
                checkoutStepEvent2.applied_loyalty = hasLoyaltyPoints;
                checkoutStepEvent2.order_giftcart_amount = $checkoutMain.data('order-totalgiftpayment');
                checkoutStepEvent2.order_type = $checkoutMain.data('order-type');
                if (data.isInitCall) {
                    $(window).on('load', function () {
                        triggerEventAfterGALoad(checkoutStepEvent);
                        triggerEventAfterGALoad(checkoutStepEvent2);
                    });
                } else if (window.utag_data) {
                    $(document).trigger(checkoutStepEvent);
                    $(document).trigger(checkoutStepEvent2);
                }
            }

            if (data.stage !== 4 && data.stage !== 0) {
                $(document).trigger(checkoutStepEvent);
            }

            // Trigger Billing Address Step
            if (data.stage === 3) {
                checkoutStepEvent.step_number = data.stage + 2;
                checkoutStepEvent.step_name = checkoutStages[data.stage + 1];
                checkoutStepEvent.checkout_option = getOptions(data.stage + 2);
                checkoutStepEvent.pacsun_updates = $checkoutMain.data('order-pacsunupdates');
                checkoutStepEvent.sms_updates = $checkoutMain.data('order-smsupdates');
                checkoutStepEvent.applied_promo = $checkoutMain.data('order-appliedpromo');
                checkoutStepEvent.applied_loyalty = hasLoyaltyPoints;
                checkoutStepEvent.order_giftcart_amount = $checkoutMain.data('order-totalgiftpayment');
                checkoutStepEvent.order_type = $checkoutMain.data('order-type');
                $(document).trigger(checkoutStepEvent);
            }
        });
    },
    shippingOptions: function () {
        $('.shipping-method-list').change(function () {
            var $self = $(':checked', this);
            var option = getShippingOptions($self);
            var checkoutOptionEvent = $.Event('CheckoutOption'); // eslint-disable-line
            checkoutOptionEvent.step_number = '3';
            checkoutOptionEvent.checkout_option = option;
            $(document).trigger(checkoutOptionEvent);
        });
    },
    paymentOptions: function () {
        $('ul.payment-options > li > a').on('click', function () {
            var $self = $(this);
            var option = getPaymentOptions($self);
            var checkoutOptionEvent = $.Event('CheckoutOption'); // eslint-disable-line
            checkoutOptionEvent.step_number = '4';
            checkoutOptionEvent.checkout_option = option;
            $(document).trigger(checkoutOptionEvent);
        });
    },
    billintOptions: function () {
        $('.js-billing-address-checkbox').on('click', function () {
            var $self = $(this);
            var option = getBillingOptions($self);
            var checkoutOptionEvent = $.Event('CheckoutOption'); // eslint-disable-line
            checkoutOptionEvent.step_number = '5';
            checkoutOptionEvent.checkout_option = option;
            $(document).trigger(checkoutOptionEvent);
        });
    },
    updateCheckoutMainData: function () {
        $('body').on('update:checkoutMainData', function (_, data) {
            var $checkoutMain = $('#checkout-main');
            if (data && data.order) {
                var order = data.order;
                var giftValue = order.totals.giftPaymentTotal.value > 0 ? order.totals.giftPaymentTotal.formatted : undefined;
                var pacsunUpdates = data.contact && data.contact.contactSignup ? 'yes' : 'no';
                var smsUpdates = data.contact && data.contact.contactOrderSignup ? 'yes' : 'no';
                $checkoutMain.data('order-isbopis', order.isBopis);
                $checkoutMain.data('order-totalgiftpayment', giftValue);
                $checkoutMain.data('order-appliedpromo', order.appliedPromo);

                if (data.contact) {
                    $checkoutMain.data('order-pacsunupdates', pacsunUpdates);
                    $checkoutMain.data('order-smsupdates', smsUpdates);
                }
            }
        });
    },
    updateFilterToogleAction: function () {
        $('button[data-click_name="filter_toggle"]').on('click', function () {
            var $self = $(this);
            var action = $self.data('filter_action');

            if (action && action === 'open') {
                $self.data('filter_action', 'close');
            } else if (action && action === 'close') {
                $self.data('filter_action', 'open');
            }
        });
    },
    loginFailEvent: function () {
        $('body').on('event:LoginFail', function (_, data) {
            var loginFailEvent = $.Event('LoginFail'); // eslint-disable-line
            loginFailEvent.failReason = data.error && data.error.length > 0 ? data.error[0] : undefined;
            $(document).trigger(loginFailEvent);
        });
    },
    loginSuccessEvent: function () {
        $('body').on('event:LoginSuccess', function () {
            $(document).trigger($.Event('LoginSuccess')); // eslint-disable-line
        });
    },
    registerFailEvent: function () {
        $('body').on('event:RegisterFail', function (_, data) {
            var registerFailEvent = $.Event('RegisterFail'); // eslint-disable-line
            registerFailEvent.failReason = data.error && data.error.length > 0 ? data.error[0] : undefined;
            $(document).trigger(registerFailEvent);
        });
    },
    registerSuccessEvent: function () {
        $('body').on('event:RegisterSuccess', function () {
            $(document).trigger($.Event('RegisterSuccess')); // eslint-disable-line
        });
    },
    newletterSignupEvent: function () {
        $('body').on('event:newsletterSignup', function (_, data) {
            var newsletterSignupEvent = $.Event('newsletterSignup'); // eslint-disable-line
            var eventData = getNewsletterNormalizedData(data);
            newsletterSignupEvent.category = eventData.category;
            newsletterSignupEvent.type = eventData.eventType;
            $(document).trigger(newsletterSignupEvent);
        });
    },
    newsletterSignupSuccessEvent: function () {
        $('body').on('event:NewsletterSignupSuccess', function (_, data) {
            var newsletterSignupSuccessEvent = $.Event('NewsletterSignupSuccess'); // eslint-disable-line
            var eventData = getNewsletterNormalizedData(data);
            newsletterSignupSuccessEvent.category = eventData.category;
            newsletterSignupSuccessEvent.type = eventData.eventType;
            $(document).trigger(newsletterSignupSuccessEvent);
        });
    },
    newsletterSignupFailEvent: function () {
        $('body').on('event:NewsletterSignupFail', function (_, data) {
            var newsletterSignupFailEvent = $.Event('NewsletterSignupFail'); // eslint-disable-line
            var eventData = getNewsletterNormalizedData(data);
            newsletterSignupFailEvent.category = eventData.category;
            newsletterSignupFailEvent.type = eventData.eventType;
            newsletterSignupFailEvent.failReason = eventData.errors ? eventData.errors : undefined;
            $(document).trigger(newsletterSignupFailEvent);
        });
    },
    storeSelectEvent: function () {
        $('body').on('event:StoreSelect', function (_, data) {
            var storeSelectEvent = $.Event('StoreSelect'); // eslint-disable-line
            storeSelectEvent.storeName = data.storeName;
            $(document).trigger(storeSelectEvent);
        });
    },
    listenerCountrySelection: function () {
        document.addEventListener('bfx-contextChooser-reload', function () {
            var country = getCookie('bfx.country');
            var currency = getCookie('bfx.currency');
            var changeCountryEvent = $.Event('ChangeCountry'); // eslint-disable-line
            changeCountryEvent.country = country;
            changeCountryEvent.currency = currency;
            $(document).trigger(changeCountryEvent);
        }, false);
    },
    formAttempt: function () {
        $('body').on('event:formAttempt', function (_, event) {
            var $target = $(event.currentTarget);

            if ($target && $target.length > 0 && $target.hasClass('js-registration-form')) {
                var fieldNamesWithErro = [];

                $target.find('.is-invalid').each(function () {
                    var $input = $(this);
                    var name = $input.parent().find('.form-control-label');

                    if (!name || name.length === 0) {
                        name = $input.parent().find('.custom-control-label');
                    }

                    var nameNormalized = name.text() ? name.text().trim() : '';
                    fieldNamesWithErro.push(nameNormalized);
                });

                var error = 'Invalid or Required Field Missing: ' + fieldNamesWithErro.toString();

                var data = {
                    error: [error]
                };

                $('body').trigger('event:RegisterFail', data);
            }
        });
    },
    initGeAnalytics: function () {
        if (window.gle) {
            // eslint-disable-next-line no-undef
            gle('OnCheckoutStepLoaded', function (data) {
                if (data.StepId === data.Steps.CONFIRMATION) {
                    var placeholder = $('.ge-analytics-placeholder');
                    var postData = { data: JSON.stringify(data), customerID: placeholder.data('cuid') };
                    postData[placeholder.data('name')] = placeholder.data('token');
                    $.ajax({
                        url: placeholder.data('endpoint'),
                        type: 'post',
                        dataType: 'html',
                        data: postData,
                        success: function (responseData) {
                            placeholder.append(responseData);
                            $(document).trigger($.Event('GleOrderConf')); // eslint-disable-line
                        }
                    });
                }
            });
        }
    }
};

module.exports = exports;
