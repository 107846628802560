'use strict';

var baseClientSideValidation = require('base/components/clientSideValidation');
var debounce = require('lodash/debounce');
var utils = require('../util/util');

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
            $('body').trigger('event:formAttempt', event);
        }
        $(this).find('input, select, textarea').each(function () {
            if (!this.validity.valid) {
                $(this).trigger('invalid', this.validity);
            }
        });
    }
    return valid;
}

/**
 *  Validate birthday input
 *  @param {Object} field - the target field
 *  @param {Object} key - the target field event
 */
function birthdayInputValidation(field, key) {
    var $field = $(field);
    var $val = $field.val();

    if (key !== 8) {
        var numChars = $val.length;
        if (numChars === 2) {
            var thisVal = $val;
            if ($val.indexOf('/') > 0) {
                return;
            }
            thisVal += '/';
            $field.val(thisVal);
        }
    }
}

/**
 *  Custom pattern validation for zipcode
 *  @param {Object} input - the target input
 */
function zipCodePattern(input) {
    var $input = $(input);
    var pattern = $input.attr('pattern');
    if (typeof pattern !== typeof undefined && $input.data('pattern-mismatch')) {
        var patternRegex = new RegExp(pattern, 'g');
        var zipValue = $.trim($input.val());
        var hasError = !zipValue.match(patternRegex);
        var errorMessage = $input.data('pattern-mismatch');
        input.setCustomValidity(hasError ? errorMessage : '');
    }
}

var exportBaseClientSideValidation = $.extend({}, baseClientSideValidation, {
    invalid: function () {
        $('body').on('invalid, blur', 'form input, form select, textarea', function (e) {
            e.preventDefault();
            this.setCustomValidity('');

            if ($(this).hasClass('zip-input') && this.validationMessage.length === 0) {
                zipCodePattern(this);
            }

            if (!this.validity.valid) {
                var validationMessage = this.validationMessage;
                $(this).addClass('is-invalid');
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                $(this).parents('.form-group').find('.invalid-feedback')
                    .text(validationMessage);
            }
        });
    },

    birthdayValidationInit: function () {
        $('#birthday, #registration-form-birthday')
            .on('keyup', function (e) {
                var keyCode = (e.keyCode ? e.keyCode : e.which);
                birthdayInputValidation(this, keyCode);

                // clearing data from birthday hidden inputs
                $('#birthDD').val('');
                $('#birthMM').val('');
            })
            .on('blur', debounce(function (e) {
                var keyCode = (e.keyCode ? e.keyCode : e.which);
                birthdayInputValidation(this, keyCode);

                // check if our input not empty and valid
                if ($(this).hasClass('text-entered') && !$(this).hasClass('is-invalid')) {
                    var value = $(this).val();

                    // update hidden inputs with date/month values
                    /* eslint-disable */
                    var parms = value.split(/[\.\-\/]/);
                    /* eslint-enable */

                    var mm = parseInt(parms[0], 10);
                    var dd = parseInt(parms[1], 10);

                    $('#birthDD').val(dd);
                    $('#birthMM').val(mm);
                }
            }, 200));
    },
    buttonClick: function () {
        $('body').on('click', 'form button[type="submit"], form input[type="submit"]', function () {
            $(this).parents('form').attr('novalidate', 'novalidate');
            // clear visible alerts
            $('.alert:visible').remove();

            if ($(this).parents('form').find('input[required]', 'select[aria-required="true"]', 'textarea[required]')) {
                var visibleInputs = $(this).parents('form').find('input:visible');
                var visibleSelects = $(this).parents('form').find('select:visible');
                var visibleTextAreas = $(this).parents('form').find('textarea:visible');
                $(visibleInputs).blur();
                $(visibleSelects).focus().blur();
                $(visibleTextAreas).focus().blur();
                var visibleInvalidInputs = $('input.is-invalid:visible, select.is-invalid:visible, textarea.is-invalid:visible', $(this).closest('form'));
                if (visibleInvalidInputs.length > 0) {
                    // scroll to any exposed errors
                    utils.scrollBrowser(visibleInvalidInputs.first().offset().top - 150);
                    visibleInvalidInputs.first().focus();
                }
            } else {
                // clear all errors when trying to submit the form
                baseClientSideValidation.clearForm($(this).parents('form'));
            }
        });
    },
    /**
     * Clears the validation errors of an invalidated form element after a change occurs
     */
    clearErrorsOnChange: function () {
        $('body').on('change input', ':input, .form-control, .custom-select', function () {
            if ($(this).hasClass('is-invalid')) {
                var formGroup = $(this).closest('.form-group');
                $(this).removeClass('is-invalid');
                formGroup.find('.input-group').removeClass('is-invalid');
                formGroup.find('.invalid-feedback').text('');
            }
        });
    },
    submit: function () {
        $('form').on('submit', function (e) {
            return validateForm.call(this, e);
        });
    }
});

module.exports = exportBaseClientSideValidation;
