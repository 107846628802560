'use strict';

/**
 *
 * @returns {boolean} is user logged in
 */
function isLoggedIn() {
    return $('.logged-in-menu').is('*');
}

/**
 *
 * @returns {number} cart quantity
 */
function getCartQty() {
    var cartQty = 0;
    if ($('.minicart .minicart-quantity').length) cartQty = parseInt($('.minicart .minicart-quantity').text(), 10);
    else if ($('.product-summary-items-count').length) cartQty = parseInt($('.product-summary-items-count').text(), 10);
    return cartQty;
}

module.exports = function () {
    if (window.bridgeWebSDK) {
        if (isLoggedIn()) {
            window.bridgeWebSDK.setCurrentLoginState(window.bridgeWebSDK.loginStates.isLoggedIn);
        } else {
            window.bridgeWebSDK.setCurrentLoginState(window.bridgeWebSDK.loginStates.isNotLoggedIn);
        }
        window.bridgeWebSDK.setCartValue(getCartQty());
    }

    $('body').on('event:LoginSuccess, event:RegisterSuccess', function () {
        if (window.bridgeWebSDK) window.bridgeWebSDK.setCurrentLoginState(window.bridgeWebSDK.loginStates.isLoggedIn);
    });

    $('body').on('event:LoginFail', function () {
        if (window.bridgeWebSDK) window.bridgeWebSDK.setCurrentLoginState(window.bridgeWebSDK.loginStates.loginError);
    });

    $('body').on('event:AddToCart', function () {
        if (window.bridgeWebSDK) window.bridgeWebSDK.setCartValue(getCartQty());
    });

    $('body').on('cart:update', function () {
        if (window.bridgeWebSDK) window.bridgeWebSDK.setCartValue(getCartQty());
    });
};
