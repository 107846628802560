/* eslint-disable no-unused-vars */
'use strict';
var slickConfigs = require('../config/slickConfigs');
var util = require('../util/util');
var loadingAttributePolyfill = require('loading-attribute-polyfill').default;

var likes = require('likes/likes/likes');

/**
 * Init globally reusable carousels
 */

/**
 * initializes the product tile carousels
 * @param {Object} callback - callback f-n
 */
function productTileCarouselInit(callback) {
    // eslint-disable-next-line consistent-return
    $('body').off('pointerenter.altview pointerleave.altview').on('pointerenter.altview pointerleave.altview', '.tile-image', function (e) {
        if (e.pointerType !== 'mouse') return false;
        var $img = $(this).find('img');
        var replaceWith = /_00_/.exec($img.attr('src')) ? '_01_' : '_00_';
        $img.attr('srcset', $img.attr('srcset').replace(/_\d\d_/g, replaceWith));
        $img.attr('src', $img.attr('src').replace(/_\d\d_/g, replaceWith));
    });

    // pre-load alt images
    $('.tile-image[data-unloaded]').each(function () {
        var $tileImg = $(this).find('noscript.loading-lazy');
        if ($tileImg.is('*')) {
            loadingAttributePolyfill.prepareElement($tileImg[0]);
        }
        var $img = $(this).find('img');
        var replaceWith = /_00_/.exec($img.attr('src')) ? '_01_' : '_00_';
        var img = document.createElement('img');
        img.src = $img.attr('src').replace(/_\d\d_/g, replaceWith);
        $(this).removeAttr('data-unloaded');
    });

    // callback after initializing all events
    if (callback) callback();
}
/**
 *
 * @param {Object} $quickViewCarousel jquery quickview carousel object
 */
function initQuickViewCarousel($quickViewCarousel) {
    var $carouselNavigation = $quickViewCarousel.siblings('.js-quick-view-navigation-wrapper').find('.js-carousel-navigation');

    $quickViewCarousel.not('.slick-initialized').slick({
        arrows: false,
        infinite: true,
        speed: 750,
        autoplaySpeed: 1000,
        cssEase: 'ease-out'
    });

    $carouselNavigation.find('li').each(function (index) {
        $(this).data('slide', index);
        $(this).on('click', function (e) {
            e.preventDefault();

            // go to selected slide onClick
            $quickViewCarousel.slick('slickGoTo', $(this).data('slide'));
        });
    });

    // On beforeSlide change updating active slides for navigation
    $quickViewCarousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var slideNumber = nextSlide;
        $carouselNavigation.find('.active').removeClass('active');
        $carouselNavigation.find('li').eq(slideNumber).addClass('active');
    });

    // On afterSlide change add custom. active class for curent slide
    $quickViewCarousel.on('afterChange', function () {
        $quickViewCarousel.find('.carouselItem.active').removeClass('active');
        $quickViewCarousel.find('.slick-active').addClass('active');
    });
}

/**
 * Sets an interval
 * @param {string} selector working selector
 * @param {function} callback Callback function for passing the carousel
 */
function waitForCarousel(selector, callback) {
    var $slickCounter = setInterval(function () {
        var $carousel = $(selector);
        if ($carousel.length < 1) {
            return;
        }
        clearInterval($slickCounter);
        callback($carousel);
    }, 100);
}

/**
 * Setup Mutation Observers for Recommendations Slots
 * When recommendations markup is loaded, fire off Mutation Observer callback
 * - Init Product Tile Carousel on Recommendation Product Tiles
 */
function initRecommendations() {
    // eslint-disable-next-line no-shadow
    var observer = new MutationObserver(function (mutations, observer) {
        var $this = $(mutations[0].target);


        // This is the logic to ensure that initRecommendations();
        // is only fired once. You could also use a Singleton pattern
        var $recommendationCarousel = $this.find('.js-recommendation-carousel');
        if ($recommendationCarousel.length && !$recommendationCarousel.hasClass('slick-initialized')) {
            // Remove CQuotient event tags
            $recommendationCarousel.find('script').remove();
            $recommendationCarousel.not('.slick-initialized').slick(slickConfigs.productTileSlider);
        }

        // This is the logic to ensure that initRecommendations();
        // is only fired once. You could also use a Singleton pattern
        var $miniCartCarousel = $this.find('.js-mini-cart-recommendation-carousel');
        $('.js-mini-cart-recommendation-carousel').find('script').remove();
        if ($miniCartCarousel.length && !$miniCartCarousel.hasClass('slick-initialized')) {
            // Remove CQuotient event tags
            $miniCartCarousel.not('.slick-initialized').slick(slickConfigs.miniCartProductTileSlider);
        }
    });

    // Create an observer for CQ Recommendations being injected into
    // slot containers with id starting with cq_recomm_slot
    $('[id^=cq_recomm_slot]').each(function (i, slot) {
        observer.observe(slot, {
            subtree: true,
            childList: true
        });
    });
}

module.exports = {
    reinitProductTilesliders: function () {
        $('body').on('refinement:updated search:sortUpdated pagination:lazyLoaded', function () {
            likes.guestLikes();
            productTileCarouselInit();
        });
        $('body').on('refinements:toggled pagination:update', function () {
            likes.guestLikes();
            productTileCarouselInit();
        });
    },
    pdpShopTheLookCarousel: function () {
        var $carousel = $('.shop-the-look-carousel');
        if ($carousel.is('*')) {
            $carousel.not('.slick-initialized').slick(slickConfigs.pdpShopTheLookCarousel);
            productTileCarouselInit();
        }
    },
    pdpImageCarousel: function () {
        var $body = $('body');
        var $carousel = $('.pdp-image-carousel');
        var $pdpVerticalVideo = $('.js-pdp-video.carouselItem');
        var $window = $(window);
        var windowWidth = $window.width();

        var initPlayer = function (i) {
            if (window.Vimeo) {
                var $vimeoIframe = $pdpVerticalVideo.find('iframe');
                // var vimeoUrl = $vimeoIframe.attr('src');
                var vimeoPlayer = new Vimeo.Player($vimeoIframe[0]); // eslint-disable-line no-undef
                var vimeoControls = $('.js-video-control');

                vimeoControls.removeClass('paused').addClass('playing');

                $pdpVerticalVideo.on('mouseenter', function () {
                    vimeoControls.addClass('hover');
                }).on('mouseleave', function () {
                    setTimeout(function () {
                        vimeoControls.removeClass('hover');
                    }, 200);
                });

                $pdpVerticalVideo.on('touchstart', function () {
                    vimeoControls.addClass('hover');
                }).on('touchend', function () {
                    setTimeout(function () {
                        vimeoControls.removeClass('hover');
                    }, 1000);
                });

                vimeoControls.on('click', function (e) {
                    e.preventDefault();
                    var videoStatus = vimeoControls.hasClass('playing');
                    if (videoStatus) {
                        vimeoPlayer.pause();
                        vimeoControls.removeClass('playing').addClass('paused show');
                    } else {
                        vimeoPlayer.play();
                        vimeoControls.removeClass('paused show').addClass('playing');
                    }
                });

                $vimeoIframe.css('pointer-events', 'none');

                if (util.mediaBreakpointDown('md')) {
                    // pause video on mobile page load until user swipes to slide 1
                    $carousel.on('init', function (event, slick, currentSlide, nextSlide) {
                        vimeoPlayer.pause();
                        vimeoControls.removeClass('playing').addClass('paused show');
                    });
                    $carousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                        if (currentSlide === 1) {
                            vimeoPlayer.pause();
                            vimeoControls.removeClass('playing').addClass('paused show');
                        }
                    });
                    $carousel.on('afterChange', function (event, slick, currentSlide) {
                        if (currentSlide === 1) {
                            vimeoPlayer.play();
                            vimeoControls.removeClass('paused show').addClass('playing');
                        }
                    });
                }
            } else if (i < 100) {
                setTimeout(function () { initPlayer(i + i); }, 50);
            }
        };

        // Destroy slick slider for desktop viewport
        var unslick = function () {
            $body.find('.pdp-image-carousel').each(function () {
                $(this).filter('.slick-initialized').slick('unslick');
            });
            if ($pdpVerticalVideo.length && !$pdpVerticalVideo.hasClass('player-initialized')) {
                $pdpVerticalVideo.addClass('player-initialized');
                initPlayer(0);
            }
        };

        var mobileTapSlide = function () {
            $carousel.on('click', function (e) {
                if (!$carousel.find('div.slick-active').hasClass('js-pdp-video')) {
                    $carousel.slick('slickNext');
                }
            });
        };

        var initSlider = function () {
            if ($carousel.is('*') && util.mediaBreakpointDown('md')) {
                if ($pdpVerticalVideo.length) {
                    if (!$pdpVerticalVideo.hasClass('player-initialized')) {
                        $pdpVerticalVideo.addClass('player-initialized');
                        initPlayer(0);
                    }
                    $carousel.not('.slick-initialized').slick(slickConfigs.pdpVideoImageCarousel);
                    mobileTapSlide();
                } else {
                    $carousel.not('.slick-initialized').slick(slickConfigs.pdpImageCarousel);
                    mobileTapSlide();
                }
            } else {
                unslick();
            }
        };

        // init slider onPageLoad
        initSlider();

        util.smartResize(function () {
            if ($window.width() !== windowWidth) { // safari iOS triggers resize on scroll; checking if window width actually changed prevents issues
                windowWidth = $window.width();
                if (util.mediaBreakpointDown('md')) {
                    initSlider();
                } else {
                    unslick();
                }
            }
        });
        $('body').on('pdpCarousel:created', function () {
            $carousel = $('.pdp-image-carousel');
            $pdpVerticalVideo = $('.js-pdp-video.carouselItem');
            $carousel.waitForImages(function () {
                initSlider();
            });
        });
    },
    footerStickyPromoSlider: function () {
        var $carousel = $('.header-promo-carousel');
        $carousel.slick(slickConfigs.footerStickyPromoSlider);
    },
    productTileSlider: function () {
        var $carousel = $('.js-product-tile-carousel');
        var configs = ($('.likes-recommendations').length || $('.recommendations-default').length) ? slickConfigs.likesRecommendations : slickConfigs.productTileSlider;

        var initSlider = function () {
            $carousel.not('.slick-initialized').slick(configs);
        };
        initSlider();
        util.smartResize(function () {
            initSlider();
        });
    },
    productTileAppSlider: function () {
        var $carousel = $('.js-product-tile-app-carousel');
        var configs = slickConfigs.productTileAppSlider;

        var initSlider = function () {
            $carousel.not('.slick-initialized').slick(configs);
        };
        initSlider();
        util.smartResize(function () {
            initSlider();
        });
    },
    memberExclusivesCarousel: function () {
        var $carousel = $('.js-member-exclusives-carousel');

        var initSlider = function () {
            $carousel.not('.slick-initialized').slick(slickConfigs.memberExclusivesCarousel);
        };

        initSlider();
        util.smartResize(function () {
            initSlider();
        });
    },
    miniCartCarousel: function () {
        $('body').on('miniCartCarousel:init', function () {
            var $miniCartSlider = $('.js-mini-cart-product-tile-carousel');
            $('.js-mini-cart-recommendation-carousel').find('script').remove();

            if ($miniCartSlider.hasClass('slick-initialized')) {
                $miniCartSlider.slick('unslick');
            }
            initRecommendations();
            $miniCartSlider.slick(slickConfigs.miniCartProductTileSlider);

            $(window).on('resize', function () {
                $miniCartSlider = $('.js-mini-cart-product-tile-carousel');
                if ($miniCartSlider.hasClass('slick-initialized')) {
                    $miniCartSlider.slick('unslick');
                }
                $miniCartSlider.slick(slickConfigs.miniCartProductTileSlider);
            });

            $('body').on('miniCartCarousel:refresh', function () {
                $miniCartSlider = $('.js-mini-cart-product-tile-carousel');
                $('.js-mini-cart-recommendation-carousel').find('script').remove();
                if ($miniCartSlider.hasClass('slick-initialized')) {
                    $miniCartSlider.slick('unslick');
                }
                $miniCartSlider.slick(slickConfigs.miniCartProductTileSlider);
            });
        });
    },
    quickViewCarousel: function () {
        $('body').on('shown.bs.modal', '#editProductModal', function () {
            waitForCarousel('.js-quick-view-images', function () {
                var $quickViewCarousels = $('.js-quick-view-images');

                $quickViewCarousels.each(function () {
                    initQuickViewCarousel($(this));
                });
            });
        });
    },
    quickViewCarouselVariation: function () {
        $('body').on('quickViewCarousel:created', function (e, response) {
            var $quickViewCarousel = response.carousel;
            $quickViewCarousel.waitForImages(function () {
                initQuickViewCarousel($quickViewCarousel);
            });
        });
    },
    bonusProductCarousel: function () {
        $('body').on('shown.bs.modal', '#chooseBonusProductModal', function () {
            var $quickViewCarousels = $('.js-quick-view-images');
            $quickViewCarousels.each(function () {
                var $quickViewCarousel = $(this);
                var $carouselNavigation = $quickViewCarousel.siblings('.js-quick-view-navigation-wrapper').find('.js-carousel-navigation');

                $quickViewCarousel.not('.slick-initialized').slick({
                    arrows: false,
                    infinite: true,
                    speed: 750,
                    autoplaySpeed: 1000,
                    cssEase: 'ease-out'
                });

                $carouselNavigation.find('li').each(function (index) {
                    $(this).data('slide', index);
                    $(this).on('click', function (e) {
                        e.preventDefault();

                        // go to selected slide onClick
                        $quickViewCarousel.slick('slickGoTo', $(this).data('slide'));
                    });
                });

                // On beforeSlide change updating active slides for navigation
                $quickViewCarousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                    var slideNumber = nextSlide;
                    $carouselNavigation.find('.active').removeClass('active');
                    $carouselNavigation.find('li').eq(slideNumber).addClass('active');
                });

                // On afterSlide change add custom. active class for curent slide
                $quickViewCarousel.on('afterChange', function () {
                    $quickViewCarousel.find('.carouselItem.active').removeClass('active');
                    $quickViewCarousel.find('.slick-active').addClass('active');
                });
            });
        });
    },
    likesCarousel: function () {
        var $carousel = $('.likes-carousel');

        var initSlider = function () {
            $carousel.not('.slick-initialized').slick(slickConfigs.likesCarousel);
        };

        initSlider();
        util.smartResize(function () {
            initSlider();
        });
    },
    shopNowCarousel: function () {
        var $carousel = $('.shop-now-carousel');

        var initSlider = function () {
            $carousel.not('.slick-initialized').slick(slickConfigs.shopNowCarousel);
        };

        initSlider();
        util.smartResize(function () {
            initSlider();
        });
    },
    initRecommendations: initRecommendations,
    productTileCarouselInit: productTileCarouselInit

};
