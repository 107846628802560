'use strict';

module.exports = function () {
    /**
     * Toggle the flyout navigation
     * @param {event} event - click event
     * @param {HTMLElement} toggler - the target toggler
     */
    function navbarTogglerHandler(event, toggler) {
        event.preventDefault();
        $('body').addClass('mobile-nav-height');
        $('.modal-background').show();

        if ($('.womens-tab').hasClass('active')) {
            $('.js-mens-menu').appendTo($('.app-menu-container'));
            $('.js-unisex-menu').appendTo($('.app-menu-container'));
            $('.js-kids-menu').appendTo($('.app-menu-container'));
            $('.js-womens-menu').appendTo($('.navbar-app'));
            $('.js-womens-menu').removeClass('d-none');
        }
        if ($('.mens-tab').hasClass('active')) {
            $('.js-womens-menu').appendTo($('.app-menu-container'));
            $('.js-unisex-menu').appendTo($('.app-menu-container'));
            $('.js-kids-menu').appendTo($('.app-menu-container'));
            $('.js-mens-menu').appendTo($('.navbar-app'));
            $('.js-mens-menu').removeClass('d-none');
        }

        if ($('.unisex-tab').hasClass('active')) {
            $('.js-womens-menu').appendTo($('.app-menu-container'));
            $('.js-mens-menu').appendTo($('.app-menu-container'));
            $('.js-kids-menu').appendTo($('.app-menu-container'));
            $('.js-unisex-menu').appendTo($('.navbar-app'));
            $('.js-unisex-menu').removeClass('d-none');
        }

        if ($('.kids-tab').hasClass('active')) {
            $('.js-womens-menu').appendTo($('.app-menu-container'));
            $('.js-mens-menu').appendTo($('.app-menu-container'));
            $('.js-unisex-menu').appendTo($('.app-menu-container'));
            $('.js-kids-menu').appendTo($('.navbar-app'));
            $('.js-kids-menu').removeClass('d-none');
        }

        var currMainMenu = $(toggler).closest('header').find('.main-menu');
        currMainMenu.toggleClass('in');
        currMainMenu.removeClass('d-none');
        currMainMenu.attr('aria-hidden', 'false');
        currMainMenu.siblings().attr('aria-hidden', 'true');

        $('header').siblings().attr('aria-hidden', 'true');
        $('.main-menu .nav.navbar-nav .nav-link').first().focus();
    }

    // Open main flyout navigation on app navbar click
    var appNavbarToggler = $('.navbar-toggler--app');
    appNavbarToggler.off('click');
    appNavbarToggler.on('click', function () {
        var mainNavbarToggler = $('.navbar-toggler').not('.navbar-toggler--app');
        if (mainNavbarToggler.length) {
            mainNavbarToggler.trigger('click');
        }
    });
    // Open app flyout navigation on some element click
    $('body').on('click', '.js-toggle-navbar--app', function (event) {
        navbarTogglerHandler(event, appNavbarToggler);
    });

    // Close app flyout navigation
    $('.header-app .navbar .close-menu-link, .header-app .navbar .navbar-attic-search-link').on('click', function (e) {
        e.preventDefault();
        $('body').removeClass('mobile-nav-height');
        $('.menu-container').find('.row').removeClass('h-100');
        $('.menu-container').removeClass('h-100');
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();

        $('.navbar-toggler').focus();

        $('.main-menu').attr('aria-hidden', 'true');
        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');
    });
};
