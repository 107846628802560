'use strict';

var cart = require('../cart/cart');

var updateMiniCart = true;

module.exports = function () {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.cart-wo-quantity')
                .removeClass('d-flex')
                .addClass('d-none');
            $('.cart-w-quantity').removeClass('d-none');
            if (!($('body').find('.cartAddedADP').length > 0)) {
                var minicartMsg = $('<span class="cartAddedADP hidden" role=alert>Item added to the cart</span>');
                $(minicartMsg).insertBefore('.minicart-total');
            }
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $('.minicart').on('mouseenter focusin touchstart', function () {
        var url = $('.minicart').data('action-url');
        var count = parseInt($('.minicart .minicart-quantity').text(), 10);
        var isCart = !!($('.page').data('action') === 'Cart-Show');

        if ($(window).outerWidth() > 767 && !isCart) {
            if (count !== 0 && $('.minicart .popover.show').length === 0) {
                if (!updateMiniCart) {
                    $('.minicart .popover').addClass('show');
                    $('body').addClass('popover-show');
                    $('body').trigger('miniCartCarousel:refresh');
                    return;
                }
                $('.minicart .popover').addClass('show');
                $('body').addClass('popover-show');

                $.get(url, function (data) {
                    $('.minicart .popover').empty();
                    $('.minicart .popover').append(data);

                    updateMiniCart = false;

                    $('body').trigger('miniCartCarousel:init');
                    $('.js-mini-cart-recommendation-carousel').find('script').remove();
                });
            }
        }
        $('.mobile-mini-cart-close-btn').on('click', function () {
            $('.mini-cart-popover').removeClass('show');
            $('body').removeClass('popover-show');
        });
    });
    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
            $('body').removeClass('popover-show');
        }
    });
    $('.minicart').on('mouseleave focusout', function (event) {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open') || event.relatedTarget === null) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
        $('body').removeClass('popover-show');
    });
    $('#like-icon').on('focus', function () {
        $('.minicart .popover').removeClass('show');
        $('body').removeClass('popover-show');
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
    $('body').on('miniCartCarousel:init', function () {
        if ($('.js_paypal_minicart_button').length) {
            $('.js_paypal_minicart_button').each(function () {
                var $paypalButton = $(this);
                if ($paypalButton.data('isInited')) {
                    return;
                }
                var config = $paypalButton.data('paypalConfig');
                if (typeof config !== 'object') {
                    // console.error('paypalMFRA: not valid data-paypal-config');
                    return;
                }
                if (config.billingAgreementFlow) {
                    var billingAgreementFlowConfig = {
                        isShippingAddressExist: config.billingAgreementFlow.isShippingAddressExist,
                        startBillingAgreementCheckoutUrl: config.billingAgreementFlow.startBillingAgreementCheckoutUrl
                    };
                    if (!config.style) {
                        config.style = {
                            shape: 'rect',
                            layout: 'horizontal',
                            tagline: false,
                            label: 'paypal'
                        };
                    }
                    config.createOrder = function () { };
                    config.onApprove = function () { };
                    config.validate = function (actions) {
                        return actions.disable();
                    };
                    config.onClick = function () {
                        if (billingAgreementFlowConfig.isShippingAddressExist === true) {
                            window.location.href = billingAgreementFlowConfig.startBillingAgreementCheckoutUrl;
                            return;
                        }
                        var $paypalAddShippingAddressModal = $('#paypalAddShippingAddressModal');
                        $('body').append($paypalAddShippingAddressModal);
                        $('#paypalAddShippingAddressModal').modal();
                    };
                    delete config.billingAgreementFlow;
                }
                // eslint-disable-next-line no-undef
                paypalUtils.initButton(config, $paypalButton[0]);
                $paypalButton.data('isInited', true);
            });
        }
    });
};
