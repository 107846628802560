'use strict';

/**
 * popupVerticalCenter - function open modal
 */
function popupVerticalCenter() {
    var marginTop = $('.modal-dialog').css('margin-top');
    var topAdj = parseInt(marginTop, 10);

    topAdj = isNaN(topAdj) ? 0 : topAdj;

    if (window.innerHeight > $('.modal-dialog').outerHeight()) {
        $('.modal-dialog').css('top', ((window.innerHeight - $('.modal-dialog').height() - (topAdj * 2)) / 2) + 'px');
    } else {
        $('.modal-dialog').css('top', -(topAdj) + 'px');
    }

    $('#afterpayModal').show();
}

var afterpay = {

    init: function () {
        $('div[itemid="#product"], .product-detail, .cart-page, .tab-content').on('click', '.afterpay-link a', openAfterpayModal); // eslint-disable-line

        $('body').on('click', '#afterpayModal .modal-header button', function () {
            $('#afterpayModal').remove();
            $('body').removeClass('modal-open');
        });

        // Dynamic Afterpay
        var jsInstanceType = document.URL.indexOf('www.pacsun.com') > 0 ? '.afterpay' : '.sandbox.afterpay';
        var jsScript = document.createElement('script');
        if (window.utag_data && (window.utag_data.page_name === 'basket' || window.utag_data.page_type === 'product')) {
            jsScript.type = 'text/javascript';
            jsScript.src = 'https://js' + jsInstanceType + '.com/afterpay-1.x.js';
            $('footer').append(jsScript);
        } else if (window.utag_data && window.utag_data.page_name === 'checkout') {
            jsScript.type = 'text/javascript';
            jsScript.src = 'https://portal' + jsInstanceType + '.com/afterpay.js';
            $('footer').append(jsScript);
        }
    }
};

/**
 * openAfterpayModal - function open modal
 * @param {Object} e - element
 */
function openAfterpayModal(e) {
    e.preventDefault();

    var url = $(this).prop('href');

    $.get(url, function (data) {
        if ($('#afterpayModal').length) {
            $('#afterpayModal').remove();
        }
        var htmlString = '<!-- Modal -->'
            + '<div class="modal fade afterpayModal" id="afterpayModal" role="dialog">'
            + '<div class="modal-dialog quick-view-dialog">'
            + '<!-- Modal content-->'
            + '<div class="modal-content">'
            + '<div class="modal-header">'
            + '    <button type="button" class="close pull-right" data-dismiss="modal">'
            + '        &times;'
            + '    </button>'
            + '</div>'
            + '<div class="modal-body">' + data + '</div>'
            + '</div>'
            + '</div>'
            + '</div>';
        $('body').append(htmlString);
        $('body').addClass('modal-open');
        $('#afterpayModal').addClass('show');

        popupVerticalCenter();

        $('#overview-container-circles img').on('load', function () {
            afterpay.popupVerticalCenter();
        });
    });
}

module.exports = afterpay;
