/* eslint-disable block-scoped-var */
'use strict';

var base = require('./base');

var populateMiniCart = function () {
    var url = $('.minicart').data('action-url');
    var count = parseInt($('.minicart .minicart-quantity').text(), 10);
    var duration = 6000;

    if (count !== 0 && $('.minicart .popover.show').length === 0) {
        if ($(window).innerWidth() < 767) {
            var mobileUrl = $('#mobileMiniCartModal').data('action-url');
            $('#mobileMiniCartModal').modal();

            $.get(mobileUrl, function (mobileMiniCartData) {
                $('.minicart-pp-btn').remove();
                $('#mobileMiniCartModal .modal-content').append(mobileMiniCartData);
                $('body').trigger('miniCartCarousel:init');
                $.spinner().stop();
            });

            setTimeout(function () {
                $('#mobileMiniCartModal').find('.close').trigger('click');
            }, duration);
        } else {
            $('.minicart .popover').addClass('show');
            // $('.minicart .popover').spinner().start();

            $.get(url, function (miniCartData) {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(miniCartData);
                $('body').trigger('miniCartCarousel:init');
                $.spinner().stop();
            });

            $('.desktop-mini-cart').removeClass('d-none');
            $('.mobile-mini-cart').addClass('d-md-none');
            if (!$('html').hasClass('un-assistive')) {
                setTimeout(function () {
                    $('.minicart .popover').removeClass('show');
                    $('.modal-background').removeClass('d-block');
                }, duration);
            }
        }
    }
};

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    $('.js-quick-shop-panel').removeClass('d-flex').addClass('d-none');
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        base.methods.editBonusProducts(response.newBonusDiscountLineItem);
    } else {
        populateMiniCart();
    }
}

/**
 * Process the attribute values for an attribute
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $quickshopParent - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processValues(attr, $quickshopParent, msgs) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $quickshopParent.find('[data-attr="' + attr.id + '"][data-attr-value="' + attrValue.value + '"]');
        var swatchClass = attr.id === 'color' ? '.swatch-mark' : '.swatch-span';

        if (attrValue.selected) {
            if (attr.id !== 'color') {
                $attrValue.addClass('selected');
            }
            if (attr.id === 'size') {
                $attrValue.addClass('btn-primary');
            }
            $attrValue.children(swatchClass).addClass('selected');
            $attrValue.children('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.removeClass('selected btn-primary');
            $attrValue.children(swatchClass).removeClass('selected');
            $attrValue.children('.selected-assistive-text').empty();
        }

        if (attrValue.url) {
            $attrValue.data('href', attrValue.url);
        } else {
            $attrValue.removeAttr('data-href');
        }

        // Disable if not selectable
        if (attrValue.selectable) {
            $attrValue.removeClass('disabled');
            $attrValue.closest('li').removeClass('disabled');
        } else if (attr.id !== 'color') {
            $attrValue.addClass('disabled');
            $attrValue.closest('li').addClass('disabled');
        }
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $quickshopContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $quickshopContainer, msgs) {
    attrs.forEach(function (attr) {
        var $quickshopParent = $quickshopContainer.find('.js-quick-shop-attribute-' + attr.id);
        processValues(attr, $quickshopParent, msgs);
    });
}

// calculating equal width for attributes.
var quickShopAttributesSizing = function (element) {
    var maxWidth = 0;
    var $sizeSwatches = element.find('.js-quick-shop-attribute');
    $sizeSwatches.each(function () {
        if ($(this).outerWidth() > maxWidth) { maxWidth = $(this).outerWidth(); }
    });
    $sizeSwatches.css('width', maxWidth);
};

module.exports = {
    init: function () {
        // reinitializing all quickShop logic after Pagination action
        $('body').on('grid:update', function () {
            window.console.log('updated grid');
            exports.openQuickShop();
            exports.showQuickShop();
            exports.quickAddToCart();
            exports.openSwatches();
            exports.selectQuickAttribute();
        });
    },
    showQuickShop: function () {
        var util = require('../util/util');
        $('body')
            .on('mouseenter', '.js-quick-shop-wrapper', function () {
                if ($(this).find('.js-quick-shop-panel').hasClass('d-none')) {
                    $(this).find('.js-quick-shop-btn')
                        .removeClass('d-none');
                    if (util.mediaBreakpointUp('md') && $(this).find('.js-quick-shop-attribute').length !== 0) {
                        var selectedEle = $(this).find('.js-quick-shop');

                        if (selectedEle.parents('.js-mini-cart-product-tile-carousel-slide').length < 1) {
                            selectedEle.removeClass('d-none');
                            selectedEle.addClass('d-flex');
                        }
                    }
                }
            })
            .on('mouseleave', '.js-quick-shop-wrapper', function () {
                $(this).find('.js-quick-shop-btn')
                    .addClass('d-none');
            })
            .on('mouseup', function (e) {
                if ((!$('.js-quick-shop-panel').is(e.target) && $('.js-quick-shop-panel').has(e.target).length === 0) &&
                    (!$('.js-quick-shop-btn').is(e.target) && $('.js-quick-shop-btn').has(e.target).length === 0)) {
                    if (util.mediaBreakpointUp('md')) {
                        $(this)
                            .find('.js-quick-shop')
                            .addClass('d-none')
                            .removeClass('d-flex');
                    }
                    $('.js-quick-shop-panel')
                        .removeClass('d-flex')
                        .addClass('d-none');
                }
            });
    },
    openQuickShop: function () {
        $('body').on('click', '.js-quick-shop-btn', function () {
            $(this).addClass('d-none');
            if (!$(this).hasClass('quickshop-called')) {
                $(this)
                    .closest('.js-quick-shop-wrapper')
                    .find('button.js-quick-shop-attribute')
                    .first()
                    .trigger('click');
                $(this).addClass('quickshop-called');
            }
            $(this).siblings('.js-quick-shop-panel')
                .removeClass('d-none')
                .addClass('d-flex');
            $(this).siblings('.js-quick-shop-panel').find('.js-quick-shop-cart-btn').addClass('d-none');
            $(this).siblings('.js-quick-shop-panel').find('.js-quick-shop-error-msg').removeClass('d-none');
            // calculating attribute width's
            quickShopAttributesSizing($(this).siblings('.js-quick-shop-panel'));
        });
    },
    quickAddToCart: function () {
        $('body').on('click', '.js-quick-shop-cart-btn', function () {
            var addToCartUrl = $(this).siblings('.add-to-cart-url').val();
            var $this = $(this);

            $('body').trigger('product:beforeAddToCart', this);

            var selectedOptions = {
                product_id: $(this).attr('data-pid'),
                pname: $(this).attr('data-pname'),
                brand: $(this).attr('data-brand'),
                price: $(this).attr('data-price'),
                Quantity: parseFloat($(this).attr('data-quantity')),
                category: $(this).attr('data-category'),
                product_color: $(this).attr('data-product_color'),
                bopis: 'no',
                discount: $(this).attr('data-discount'),
                product_sku: $(this).attr('data-product_sku'),
                markdown_type: undefined,
                product_dept: $(this).attr('data-product_department'),
                product_division: $(this).attr('data-product_division'),
                product_subdept: $(this).attr('data-product_subdepartment'),
                order_type: 'standard order'
            };

            var form = {
                pid: $(this).attr('data-product_sku'),
                // TODO: Update w/ quantity input from user
                quantity: 1
            };

            if ($(this).attr('data-flag')) form.flag = $(this).attr('data-flag').replace('$', (form.pid + '').substring(2, 5));

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.spinner().start();
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        handlePostCartAdd(data);
                        $('body').trigger('miniCartCarousel:init');
                        $('body').trigger('product:afterAddToCart', data);

                        selectedOptions.discount = data.productAdded ? data.productAdded.getDiscount : '';
                        selectedOptions.price = data.productAdded ? data.productAdded.price.sales.formatted : '';
                        selectedOptions.product_sku = data.productAdded ? data.productAdded.id : '';
                        selectedOptions.category = data.productAdded ? data.productAdded.primaryCategory : '';
                        selectedOptions.product_color = data.productAdded && data.productAdded.variationAttributes.length > 1 ? data.productAdded.variationAttributes[1].displayValue : '';
                        $('body').trigger('event:AddToCart', selectedOptions);

                        if ($this.parents('.cart-recommendations').length > 0) {
                            // prevent page scrolls to the previous position after reloading.
                            // always loads at the top of the page
                            history.scrollRestoration = 'manual';
                            document.location.reload(true);
                        } else {
                            $.spinner().stop();
                        }
                    },
                    error: function (err) {
                        var errorMsg = err.responseJSON
                            ? err.responseJSON.errorMessage
                            : 'Something went wrong, please try again.';
                        $this.siblings().children('.quick-view-select-error').text(errorMsg);
                        $.spinner().stop();
                    }
                });
            }
        });
    },
    openSwatches: function () {
        $('body').on('click', '.js-quick-shop-attribute-wrapper .open-swatches', function () {
            $(this).siblings('.color-container').removeClass('d-none');
            $(this).addClass('d-none');
        });
    },
    selectQuickAttribute: function () {
        $('body').on('click', '.js-quick-shop-attribute', function () {
            var url = $(this).data('href');
            var $quickshopContainer = $(this).parents('.js-quick-shop-attributes');
            var $addToCartBtn = $quickshopContainer.siblings('.js-quick-shop-cart-btn');
            var $selectSpan = $quickshopContainer.siblings('.js-quick-shop-error-msg').find('.select-attributes-span');
            var isMinicart = $(this).parents().hasClass('minicart');

            if (!isMinicart) $(this).parents('.js-quick-shop-wrapper').spinner().start();

            $.ajax({
                url: url,
                method: 'GET',
                success: function (response) {
                    updateAttrs(response.product.variationAttributes, $quickshopContainer, response.resources);
                    // $addToCartBtn.attr('data-pid', response.product.id);
                    $addToCartBtn.attr('data-product_sku', response.product.id);
                    if (response.product.readyToOrder) {
                        // Update data on button after fully qualified variant
                        if (!response.product.price.type) {
                            $addToCartBtn.attr('data-price', response.product.price.sales.formatted);
                        }
                        $addToCartBtn.attr('data-variant-selections', JSON.stringify(response.product.variantSelections));
                        $selectSpan.addClass('d-none');
                        $addToCartBtn.removeAttr('disabled');
                        $addToCartBtn.removeClass('d-none');
                    } else {
                        $selectSpan.removeClass('d-none');
                        $addToCartBtn.attr('disabled', true);
                        $addToCartBtn.addClass('d-none');
                    }
                    if (!isMinicart) $addToCartBtn.parents('.product').spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },
    miniCartADA: function () {
        $('.minicart').on('click', 'button.close', function () {
            $('.minicart .popover').removeClass('show');
            $('.modal-background').removeClass('d-block');
        });
    }
};
