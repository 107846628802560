'use strict';

/**
 * Renders consent banner that will track the users consenting to accepting site tracking policy
 */
function showConsent() {
    if (!$('.tracking-consent').data('caonline')) {
        return;
    }

    var urlContent = $('.tracking-consent').data('url');
    var urlAccept = $('.tracking-consent').data('accept');

    $.ajax({
        url: urlContent,
        type: 'GET',
        dataType: 'html',
        success: function (response) {
            $('#consent-tracking').html(response).removeClass('d-none');
        },
        error: function () {
            $('#consent-tracking').remove();
        }
    });

    $('body').on('click', '#consent-tracking .privacy-policy-close-button', function (e) {
        e.preventDefault();
        $.ajax({
            url: urlAccept,
            type: 'GET',
            dataType: 'json',
            success: function () {
                $('#consent-tracking').remove();
            },
            error: function () {
                $('#consent-tracking').remove();
            }
        });
    });
}

module.exports = function () {
    if ($('.consented').length === 0 && $('.tracking-consent').hasClass('api-true')) {
        showConsent();
    }

    if ($('.tracking-consent').hasClass('api-true')) {
        $('.tracking-consent').on('click', function () {
            showConsent();
        });
    }
};
