'use strict';

var mobileMenu = {
    isInit: false,
    init: function () {
        var $menu = $('#mobile-nav');
        var jsonMenuString = $('#mobileMenuJson').html();
        if (jsonMenuString) this.createMobileMenu(jsonMenuString, $menu);
        this.isInit = true;
    },
    createMobileMenu: function (jsonString, $menu) {
        try {
            this.createMenuItems(JSON.parse(jsonString), 1, $menu);
        } catch (e) {
            // error
        }
    },
    createMenuItems: function (menuItems, level, $menu) {
        var itemsLength = menuItems.length;
        var i = 0;
        var $li;
        var $ul;
        var $img = '<img class="float-right pt-1" src="/on/demandware.static/-/Sites-pacsun-Library/default/nav/mobileNavForwardArrow.svg" />';
        var $imgSale = '<img class="float-right pt-1" src="/on/demandware.static/-/Sites-pacsun-Library/default/nav/mobileNavForwardArrowRed.svg" />';

        for (i; i < itemsLength; i++) {
            // eslint-disable-next-line no-restricted-syntax
            for (var p in menuItems[i]) {
                // eslint-disable-next-line no-continue, no-prototype-builtins
                if (!menuItems[i].hasOwnProperty(p)) continue;

                if (typeof (menuItems[i][p]) === 'string') {
                    if (level === 3) {
                        $li = $('<li />', { 'data-click_category': 'navigation', 'data-click_action': 'mobile navigation', 'datadata-click_section': p, class: 'dropdown-item', role: 'presentation' }).html('<a href="' + menuItems[i][p] + '" class="dropdown-link font-weight-500" role="menuitem" tabindex="-1">' + p + $img + '</a>');
                    } else if (level === 2 && p.indexOf('Sale') > -1) {
                        $li = $('<li />', { 'data-click_category': 'navigation', 'data-click_action': 'mobile navigation', 'datadata-click_section': p, class: 'dropdown-item sale-dropdown', role: 'presentation' }).html('<a href="' + menuItems[i][p] + '" class="dropdown-link text-secondary font-weight-500" role="menuitem" tabindex="-1">' + p + $imgSale + '</a>');
                    } else if (p.indexOf('Pre-Loved') > -1) {
                        $li = $('<li />', { 'data-click_category': 'navigation', 'data-click_action': 'mobile navigation', 'datadata-click_section': p, class: 'nav-item dropdown nav-dropdown-position', role: 'presentation' }).html('<a href="javascript:void(0);" class="nav-link dropdown-toggle font-weight-500" role="button" data-toggle="modal" data-target="#myModal" tabindex="0">' + p + '</a>');
                    } else {
                        $li = $('<li />', { 'data-click_category': 'navigation', 'data-click_action': 'mobile navigation', 'datadata-click_section': p, class: 'nav-item dropdown nav-dropdown-position', role: 'presentation' }).html('<a href="' + menuItems[i][p] + '" class="nav-link dropdown-toggle font-weight-500" role="menuitem" tabindex="-1">' + p + $img + '</a>');
                    }
                } else {
                    $ul = $('<ul />', { 'data-level': level, class: 'dropdown-menu', role: 'menu', 'aria-hidden': 'true' });

                    if (level === 1) {
                        $li = $('<li />', { 'data-click_category': 'navigation', 'data-click_action': 'mobile navigation', 'datadata-click_section': p, class: 'nav-item dropdown nav-dropdown-position', role: 'presentation' }).html('<a href="javascript:void(0);" id="' + p + '" class="nav-link dropdown-toggle font-weight-500" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" tabindex="0">' + p + $img + '</a>').append($ul);
                    } else {
                        $li = $('<li />', { 'data-click_category': 'navigation', 'data-click_action': 'mobile navigation', 'datadata-click_section': p, class: 'nav-item dropdown nav-dropdown-position', role: 'presentation' }).html('<a href="javascript:void(0);" class="nav-link dropdown-toggle sub-cat-item font-weight-500" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" tabindex="0">' + p + $img + '</a>').append($ul);
                    }

                    this.createMenuItems(menuItems[i][p], level + 1, $ul);
                }
                $li.appendTo($menu);
            }
        }
    }
};
module.exports = mobileMenu;
