'use strict';

var base = require('base/components/footer');
var debounce = require('lodash/debounce');
var util = require('../util/util');

/**
 * sets session to hide promo menu after user dismissal
 */
function closePromos() {
    $.ajax({
        url: $('.js-close-promos').data('action'),
        method: 'GET',
        success: function () {
            $('.js-close-promos').remove();
        },
        error: function () {
            $('.js-close-promos').remove();
        }
    });
}

var exports = {
    base: base,
    closeStickyFooterPromo: function () {
        $('.header-promo-close-button').on('click', function () {
            closePromos();
        });
    },
    validateNumber: function () {
        $('input[name=mobileNumber]').on('keydown', function (e) {
            var a = [46];
            var k = e.which;
            a.push(8);
            a.push(0);
            var i;
            for (i = 48; i < 58; i++) {
                a.push(i);
            }

            if (!($.inArray(k, a) >= 0) && k !== 9) {
                e.preventDefault();
            }
        });
    },
    countryChange: function () {
        // Select the node that will be observed for mutations
        var targetNode = $('.js-bf-country-chooser-wrapper')[0];

        // Options for the observer (which mutations to observe)
        var config = {
            childList: true
        };

        // Callback function to execute when mutations are observed
        var callback = function (mutationsList, observer) {
            // Use traditional 'for loops' for IE 11
            mutationsList.forEach(function (mutation) {
                mutation.addedNodes.forEach(function (addedNode) {
                    if (addedNode.id === 'bfx-cc-wrapper-collapsed') {
                        var currency = $('.bfx-cc-currencies').children(':selected').text().trim();
                        var country = $('.bfx-cc-countries').children(':selected').text().trim();
                        $('.js-bf-country-chooser').text(country + ' / ' + currency);
                        observer.disconnect();
                    }
                });

                $('.js-bf-country-chooser').on('click', function () {
                    $('.bfx-cc-collapsed').trigger('click');
                });
            });
        };

        // Create an observer instance linked to the callback function
        var observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        if (targetNode) {
            observer.observe(targetNode, config);
        }
    },
    setYear: function () {
        var currentYear = $('#current-year').val();
        $('.footer-year').html(currentYear);
    },
    setUserAgent: function () {
        if (util.isAndroid()) {
            $('body').addClass('android');
        }
    },
    signup: function () {
        $('#sign-up-footer-form').on('submit', function (e) {
            $.spinner().start();
            e.preventDefault();

            var $self = $(this);
            var signupErrors = $('.form-errors');
            var serverError = 'Please verify the email and mobile number are correct and try again.';
            var formData = {
                email: $self.find('input[name="email"]').val(),
                mobileNumber: $self.find('input[name="mobileNumber"]').val(),
                shoppingcatwomen: $self.find('input[name="shoppingcatwomen"]').is(':checked'),
                shoppingcatmen: $self.find('input[name="shoppingcatmen"]').is(':checked'),
                shoppingcatunisex: $self.find('input[name="shoppingcatunisex"]').is(':checked'),
                shoppingcatkids: $self.find('input[name="shoppingcatkids"]').is(':checked'),
                emailAcqSource: $self.find('input[name="emailAcqSource"]').val(),
                errors: []
            };

            if (formData.email) {
                signupErrors.hide();
                $('body').trigger('event:newsletterSignup', formData);

                $.ajax({
                    url: this.action,
                    type: 'post',
                    data: $(this).serialize(),
                    success: function (data) {
                        if (data.success) {
                            $('#js-signin-container').hide();
                            $('#js-signed-success').show();
                            $('body').trigger('event:NewsletterSignupSuccess', formData);
                        } else {
                            // signupErrors.html(serverError).show();
                            formData.errors = data.errorMessages ? data.errorMessages : undefined;
                            $('body').trigger('event:NewsletterSignupFail', formData);
                        }
                        $.spinner().stop();
                    },
                    error: function () {
                        signupErrors.html(serverError).show();
                        $.spinner().stop();
                        formData.errors.push('Unexpected Error to Register in the Newsletter from footer section');
                        $('body').trigger('event:NewsletterSignupFail', formData);
                    }
                });
            } else {
                // $('#newsForm-email-error').show();
                $.spinner().stop();
            }
        });
    },
    skipNonFocusableModalElements: function () {
        $('.modal-content a:last-child').on('focusout', function () {
            $('.modal-content button').focus();
        });
        $('body').on('focusout', '#loyaltyCard .modal-content button', function () {
            $('.modal-content button').focus();
        });
        $('.heroandslider .carouselItem picture a').attr('aria-hidden', 'true').attr('tabindex', '-1');
    }
};

module.exports = exports;
