'use strict';

var utils = {
    /**
     * @desc Media breakpoints that are used throughout the JavaScript
     */
    breakpoints: {
        xs: 0,
        sm: 544,
        md: 769,
        lg: 992,
        xl: 1200
    },

    /**
     * appends params to a url
     * @param {string} url - Original url
     * @param {Object} params - Parameters to append
     * @returns {string} result url with appended parameters
     */
    appendToUrl: function (url, params) {
        var newUrl = url;
        newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
            return key + '=' + encodeURIComponent(params[key]);
        }).join('&');

        return newUrl;
    },

    /**
     * @function
     * @description Returns either an object with all of the available breakpoints or a specific viewport based on the given size
     * @param {string=} size The viewport to return
     * @param {Object=} breakpoints A custom breakpoints object
     * @returns {Object|string} - breakpoints or specific viewport
     */
    getViewports: function (size, breakpoints) {
        var bps = breakpoints || this.breakpoints;

        if (size) {
            var viewport = bps[size];

            if (viewport) {
                return viewport;
            }

            window.console.error('Unexpected viewport size given in util.getViewports: size:' + size + ', breakpoints...');
            window.console.error(breakpoints);
            throw new Error('Unexpected viewport size given in util.getViewports');
        } else {
            return breakpoints;
        }
    },

    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {string} xLocation - The x coordinate
     */
    scrollBrowser: function (xLocation) {
        $('html, body').animate({
            scrollTop: xLocation
        }, 500);
    },

    /**
     * @function
     * @desc Determines if the device that is being used is android based
     * @returns {boolean} - Wether or not the browser is currently android based
     */
    isAndroid: function () {
        var mobileAgentHash = ['android'];
        var idx = 0;
        var isAndroid = false;
        var userAgent = (navigator.userAgent).toLowerCase();

        while (mobileAgentHash[idx] && !isAndroid) {
            isAndroid = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
            idx++;
        }
        return isAndroid;
    },

    /**
     * @function
     * @description Executes a callback function when the user has stopped resizing the screen.
     * @param {function} callback - Callback function for the resize event
     * @return  {function} - Callback function for the resize event
     */
    smartResize: function (callback) {
        var timeout;

        $(window).on('resize', function () {
            clearTimeout(timeout);
            timeout = setTimeout(callback, 100);
        }).resize();

        return callback;
    },

    /**
     * @function
     * @desc Generates a min-width matchMedia media query based on the given params
     * @param {string} size - Breakpoint to use for the media query
     * @param {Object} breakpoints - Override of the util breakpoints (optional)
     * @returns {boolean} - Wether or not the given media query matches
     */
    mediaBreakpointUp: function (size, breakpoints) {
        var bps = typeof breakpoints !== 'undefined' ? breakpoints : this.breakpoints;
        var breakpoint = this.getViewports(size, bps);
        var mediaQuery = window.matchMedia('(min-width: ' + breakpoint + 'px)');
        return mediaQuery.matches;
    },

    /**
     * @function
     * @desc Generates a max-width matchMedia media query based on the given params
     * @param {string} size - Breakpoint to use for the media query
     * @param {Object} breakpoints - Override of the util breakpoints object (optional)
     * @returns {boolean} - Wether or not the given media query matches
     */
    mediaBreakpointDown: function (size, breakpoints) {
        var bps = typeof breakpoints !== 'undefined' ? breakpoints : this.breakpoints;
        var nextSize = this.getNextObjectKey(bps, size);

        if (typeof nextSize === 'string') {
            var breakpoint = this.getViewports(nextSize, breakpoints) - 1;
            var mediaQuery = window.matchMedia('(max-width: ' + breakpoint + 'px)');
            return mediaQuery.matches;
        }

        return true;
    },

    /**
     * @function
     * @desc Retrieves the next key in the object or null if it doesn't exist
     * @param {Object} obj - Object to get the next key from
     * @param {string} key - Key to base the next key index on
     * @returns {string}|{null} - The next key of the given object or null if one doesn't exist
     */
    getNextObjectKey: function (obj, key) {
        var keys = Object.keys(obj);
        var nextIndex = keys.indexOf(key) + 1;

        if (keys.length > nextIndex) {
            return keys[nextIndex];
        }

        return null;
    },

    lockBodyScroll: function () {
        var $docEl = $('html, body');
        var $wrap = $('.page');
        var scrollTop;

        if (window.pageYOffset) {
            scrollTop = window.pageYOffset;

            $wrap.css({
                top: -(scrollTop),
                position: 'relative'
            });
        }

        $docEl.css({
            height: '100%',
            overflow: 'hidden'
        });
    },

    unlockBodyScroll: function () {
        var $docEl = $('html, body');
        var $wrap = $('.page');
        var scrollTop = Math.abs(parseInt($wrap.css('top'), 10));

        $docEl.css({
            height: '',
            overflow: ''
        });

        $wrap.css({
            top: '',
            position: 'initial'
        });

        window.scrollTo(0, scrollTop);
        window.setTimeout(function () {
            scrollTop = null;
        }, 0);
    }
};

module.exports = utils;
