'use strict';
/**
 * get widget for afterpay
 *
 * @param {Object} updatedProductID - updatedProductID
 * @param {Object} updatedProductPrice - updatedProductPrice
 * @param {Object} className - className
 * @param {Object} $productContainer - productContainer
 */
function getWidget(updatedProductID, updatedProductPrice, className, $productContainer) {
    var getUpdatedWidgetUrl = $('.updated-widget').val();
    var queryString = '?productID=' + updatedProductID + '&updatedProductPrice=' + updatedProductPrice + '&className=' + className;

    $.ajax({
        url: getUpdatedWidgetUrl + queryString,
        method: 'GET',
        success: function (data) {
            if (data.updatedWidget) {
                if ($productContainer !== undefined) {
                    $productContainer.find('.afterpay-widget').html(data.updatedWidget);
                    $productContainer.find('.afterpay-widget').show();
                } else if ($productContainer === undefined) {
                    $('.afterpay-widget').html(data.updatedWidget);
                    $('.afterpay-widget').show();
                }
            }
        }
    });
}

module.exports = {
    getWidget: getWidget
};
