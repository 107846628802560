'use strict';

module.exports = {

    /**
     * @function
     * @desc Initializes floating labels on form inputs (text and textarea only)
     */
    initFloatLabels: function () {
        /**
         * @function
         * @desc Updates CSS class on text fields to reflect whether they are populated with input or not
         * @param {jQuery.target} $el The input to poll
         */
        var pollInput = function ($el) {
            if ($el.val()) {
                $el.addClass('text-entered');
            } else {
                $el.removeClass('text-entered');
            }
        };

        var $body = $('body');

        // fire the pollInput function once initially on page load
        $(document).find('input, textarea, select').each(function () {
            if ($(this).is(':visible')) {
                pollInput($(this));
            }
        });

        document.addEventListener('onautocomplete', function (e) {
            e.target.hasAttribute('autocompleted');
        });// fire the pollInput function once initially on page load

        $body.on('onautocomplete', 'input, textarea, select', function () {
            if ($(this).is(':visible')) {
                pollInput($(this));
            }
        });

        // callback for updating floated labels after ajax calls
        $body.on('updateFloatLabels', function (event, form) {
            $(form).find('input, select').each(function () {
                pollInput($(this));
            });
        });

        $body.on('blur', 'input, select', function () {
            $(this).trigger('invalid');
        });

        $body.on('click, input, change, focus, blur', '.shippingAddressOne, .billingAddressOne', function () {
            var inputCheckingCounter = 0; // start point of checkInput counter
            var form = $(this).parents('fieldset');

            var timeoutId = setInterval(function () { // interval function for checking all shipping-address-block inputs
                var emptyInputs = 0;

                form.find('input, select').each(function () {
                    var input = $(this);
                    if (input.val()) {
                        pollInput(input); // if input has value Fires input validation
                        input.removeClass('is-invalid');
                    }
                    if (!input.hasClass('text-entered')) {
                        emptyInputs++; // calculation number of empty inputs
                    }
                });
                inputCheckingCounter++;
                // clearing interval and stop our checking session if all inputs already has values
                // or our we've checked inputs for a 15 seconds
                if (emptyInputs <= 0 || inputCheckingCounter >= 30) {
                    clearInterval(timeoutId);
                }
            }, 500);
        });

        /**
         * @listener
         * @desc Fires the pollInput function once per textual input field every time a change is made
         */
        $(document).on('change keyup keypress input propertychange focus click', 'input, textarea, select, a, .btn', function () {
            pollInput($(this));
            $(document).find('input, textarea, select').each(function () {
                pollInput($(this));
            });
        });

        /**
         * @listener
         * @desc Listens for window load and dynamically adds text-entered to any Chrome autofilled fields.
         */
        $(window).on('load', function () {
            try {
                var currentInput = $('input:-webkit-autofill');
                $(currentInput).addClass('text-entered');
            } catch (error) {
                // Hack not to show errors if not chrome
            }
        });
    }
};
