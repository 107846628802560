/* eslint-disable no-underscore-dangle */
'use strict';

/**
 * isPxJSON
 * @param {Object} jqxhr - jQuery XHR
 * @returns {boolean} - true if PX JSON, false if not
 */
function isPxJSON(jqxhr) {
    return !!((jqxhr.responseJSON && jqxhr.responseJSON.blockScript));
}

/**
 * isPxHTML
 * @param {Object} jqxhr - jQuery XHR
 * @returns {boolean} - true if PX HTML, false if not
 */
function isPxHTML(jqxhr) {
    return !!((jqxhr.responseText && jqxhr.responseText.indexOf('_pxBlockScript') > -1));
}

/**
 * getPxJSON
 * @param {string} responseText - Response Text from jQuery XHR
 * @returns {Object} - JSON string of PX data scraped from HTML response
 */
function getPxJSON(responseText) {
    var appId = /_pxAppId\s?=\s?'([^']+)/.exec(responseText);
    var blockScript = /lockScript\s?=\s?'([^']+)/.exec(responseText);
    var firstPartyEnabled = /_pxFirstPartyEnabled\s?=\s?([^;]+)/.exec(responseText);
    var hostUrl = /_pxHostUrl\s?=\s?'([^']+)/.exec(responseText);
    var jsClientSrc = /_pxJsClientSrc\s?=\s?'([^']+)/.exec(responseText);
    var uuid = /_pxUuid\s?=\s?'([^']+)/.exec(responseText);
    var vid = /_pxVid\s?=\s?'([^']+)/.exec(responseText);

    return {
        appId: appId && appId.length === 2 ? appId[1] : '',
        blockScript: blockScript && blockScript.length === 2 ? blockScript[1] : '',
        firstPartyEnabled: !(firstPartyEnabled && firstPartyEnabled.length === 2 && firstPartyEnabled[1] === 'false'),
        hostUrl: hostUrl && hostUrl.length === 2 ? hostUrl[1] : '',
        jsClientSrc: jsClientSrc && jsClientSrc.length === 2 ? jsClientSrc[1] : '',
        uuid: uuid && uuid.length === 2 ? uuid[1] : '',
        vid: vid && vid.length === 2 ? vid[1] : ''
    };
}

module.exports = function () {
    $(document).ajaxError(function (event, jqxhr) {
        var pxJSON;
        if (isPxJSON(jqxhr)) pxJSON = jqxhr.responseJSON;
        else if (isPxHTML(jqxhr)) pxJSON = getPxJSON(jqxhr.responseText);
        if (pxJSON && !$('#pxModal').length) {
            var pxModalHtml = '<div class="modal-dialog modal-dialog-centered" role="document"><div class="modal-content"><div class="modal-body"><div class="h5">Please verify you are a human</div><div id="px-captcha"></div></div></div></div>';
            var $pxModal = $('<div />', { id: 'pxModal', class: 'modal', tabindex: '-1', role: 'dialog', html: pxModalHtml });

            $('body').append($pxModal);

            $pxModal.on('shown.bs.modal', function () {
                window._pxAppId = pxJSON.appId; // PerimeterX's application id
                window._pxJsClientSrc = pxJSON.jsClientSrc; // PerimeterX's JavaScript sensor url
                window._pxFirstPartyEnabled = pxJSON.firstPartyEnabled; // A boolean flag indicating wether first party is enabled or not
                window._pxVid = pxJSON.vid; // PerimeterX's visitor id
                window._pxUuid = pxJSON.uuid; // PerimeterX's unique user id
                window._pxHostUrl = pxJSON.hostUrl; // PerimeterX's cloud component URL

                var script = document.createElement('script');
                script.src = pxJSON.blockScript; // use the blockScript property from the Advanced Blocking Response result.
                document.getElementsByTagName('head')[0].appendChild(script);

                window._pxOnCaptchaSuccess = function (isValid) {
                    if (isValid) {
                        $pxModal.modal('hide');
                    }
                };
            });

            $pxModal.on('hidden.bs.modal', function () {
                $pxModal.remove();
            });

            $pxModal.modal('show');
        }
    });
};
